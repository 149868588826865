<div class="container tabs">
    <div class="custom-row">
        <div class="tabs-header custom-col"><h4>Собрание</h4></div>
        <!--    <div class="tabs-header custom-col"><h4>{{preloadMeetingNotify[0]['number_protocol']}}</h4></div>-->
    </div>
    <div class="custom-row">
        <div class="tabs-entity custom-col">
            <div class="custom-card h-100">
                <div class="card-header">
                    <h4 class="label">Этапы собрания</h4>
                </div>
                <div class="card-body">
                    <smart-tabs #phaseMeeting class="current-meeting-carusel"
                                (click)="onTabClick(phaseMeeting ,$event)"
                    >
                        <smart-tab-item label="Выбрать вопросы">
                            <h6>Текущие вопросы в собрании</h6>
                            <!--    <div class="custom-row">
                                    <div class="tabs-header custom-col"><h4>Выбранные вопросы</h4></div>
                                </div>-->
                            <smart-button (click)="addQuestion()">Новый вопрос</smart-button>
                            <smart-button (click)="copyAnotherMeeting()">Скопировать из другого собрания</smart-button>
                            <smart-button (click)="getStandartQuestion()">Шаблоны</smart-button>
                            <smart-button (click)="getMyQuestion()">Избранные вопросы</smart-button>

                            <div class="custom-row">
                                <div class="tabs-header custom-col"><!--<h4>Выбранные вопросы</h4>--></div>
                            </div>
                            <br>
                            <smart-button #button2 (onClick)="deleteSelectedCheckBox($event)"
                                          [disabled]=this.isDisabledButtonDeleteAll>
                                Удалить выбранные
                            </smart-button>
                            <div class="custom-card">
                                <div class="card-body">
                                    <smart-table #table id="table" [dataSource]="dataSource"
                                                 [paging]="questionsDataSettings.paging"
                                                 [sortMode]="questionsDataSettings.sortMode"
                                                 [tooltip]="questionsDataSettings.tooltip"
                                                 [onInit]="questionsDataSettings.onInit"
                                                 [columns]="questionsDataSettings.columns"
                                                 [filtering]="questionsDataSettings.filtering"
                                                 [filterRow]="questionsDataSettings.filterRow"
                                                 [pageIndex]="questionsDataSettings.pageIndex"
                                                 [pageSize]="questionsDataSettings.pageSize"
                                                 [selection]="questionsDataSettings.selection"
                                                 [editing]="questionsDataSettings.editing"
                                                 (onCellEndEdit)="onItemAfterEdit($event)"
                                                 (onCellClick)="onCellClick($event)"
                                                 (onChange)="onChangeCheckbox($event)"
                                    ></smart-table>
                                </div>
                            </div>
                            <smart-window #formWindow id="formWindow" [footerTemplate]="'footerTemplate'"
                                          [opened]="false" [label]="'Внимание!'"
                                          (onReady)="onWindowReady($event)">
                                <div id="article">
                                    <section>
                                        <h3>Уверены что хотите удалить выделенные вопросы?</h3>
                                        <p>При нажатии на кнопку все выделенные вопросы удаляться их нельзя будет
                                            вернуть </p>
                                    </section>
                                </div>
                            </smart-window>
                            <template id='footerTemplate'>
                                <smart-button id="сancelButton">Нет</smart-button>
                                <smart-button id="agreeHandler">Да</smart-button>
                            </template>


                        </smart-tab-item>

                        <smart-tab-item label="Участники собрания">
                            <h6>Участники собрания</h6>
                            <div>
                                <form-update-house></form-update-house>
                            </div>
                        </smart-tab-item>

                        <smart-tab-item label="Уведомление собственников">
                            <form [formGroup]="notificationOwnerForm" (ngSubmit)="createNotification()">
                                <div class="container basic-inputs">
                                    <div class="custom-row">
                                        <div class="col-md-6">
                                            <div class="custom-card">
                                                <div class="card-header">
                                                    <h4 class="label">Регистрационный номер протокола</h4>
                                                </div>
                                                <div class="card-body">
                                                    <smart-input class="basic-input"
                                                                 formControlName="numberProtocol"
                                                                 ngModel="{{this.preloadMeetingNotify['number_protocol']}}"
                                                    >
                                                    </smart-input>
                                                    <!--    &lt;!&ndash;Дебажим значения в форме чтобы глянуть какие ошибки есть&ndash;&gt;
                                                        &lt;!&ndash;  <pre>{{questionCreateForm.get('address').errors | json}}</pre>&ndash;&gt;-->
                                                    <div
                                                            *ngIf="notificationOwnerForm.get('numberProtocol').invalid && notificationOwnerForm.get('numberProtocol').touched"
                                                            class="validation"
                                                    >
                                                        <small *ngIf="notificationOwnerForm.get('numberProtocol').errors.required">
                                                            Поле номер не может быть пустым
                                                        </small>

                                                        <small *ngIf="notificationOwnerForm.get('numberProtocol').errors.minlength">
                                                            Длинна должна быть не
                                                            менее {{notificationOwnerForm.get('numberProtocol').errors.minlength.requiredLength}}
                                                            Сейчас кол-во
                                                            символов {{notificationOwnerForm.get('numberProtocol').errors.minlength.actualLength}}
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="custom-card">
                                                <div class="card-header">
                                                    <h4 class="label">Тип собрания</h4>
                                                </div>
                                                <div class="card-body">
                                                    <smart-input
                                                            class="basic-input"
                                                            drop-down-button-position="right"
                                                            formControlName="meetingPeople"
                                                            [placeholder]="'Выберете тип собрания'"
                                                            [readonly]="true"
                                                            (change)="changeMeetingPeopleId($event)"
                                                            ngModel="{{this.meetingPeopleTitle}}"
                                                    >
                                                        <option
                                                                *ngFor="let item of meetingService.meetingPeople"
                                                                value="{{item.id}}"
                                                        >
                                                            {{item.title}}
                                                        </option>
                                                    </smart-input>
                                                </div>
                                            </div>
                                            <div class="custom-card">
                                                <div class="card-header">
                                                    <h4 class="label">Очередность собрания</h4>
                                                </div>
                                                <div class="card-body">
                                                    <smart-input
                                                            class="basic-input"
                                                            drop-down-button-position="right"
                                                            formControlName="meetingQueue"
                                                            [placeholder]="'Очередное или внеочередное'"
                                                            [readonly]="true"
                                                            (change)="changeMeetingQueueId($event)"
                                                            ngModel="{{this.meetingQueueTitle}}"
                                                    >
                                                        <option
                                                                *ngFor="let item of meetingService.meetingQueue"
                                                                value="{{item.id}}"
                                                        >
                                                            {{item.title}}
                                                        </option>
                                                    </smart-input>
                                                </div>
                                            </div>
                                            <div class="custom-card">
                                                <div class="card-header">
                                                    <h4 class="label">Форма собрания</h4>
                                                </div>
                                                <div class="card-body">
                                                    <smart-input
                                                            class="basic-input"
                                                            drop-down-button-position="right"
                                                            formControlName="meetingOnline"
                                                            [placeholder]="'Очное или заочное'"
                                                            [readonly]="true"
                                                            (change)="changeMeetingOnlineId($event)"
                                                            ngModel="{{this.meetingOnlineTitle}}"
                                                    >
                                                        <option
                                                                *ngFor="let item of meetingService.meetingOnline"
                                                                value="{{item.id}}"
                                                        >
                                                            {{item.title}}
                                                        </option>
                                                    </smart-input>
                                                </div>
                                            </div>
                                            <div class="custom-card">
                                                <div class="card-header">
                                                    <h4 class="label">Ссылка на облако</h4>
                                                </div>
                                                <div class="card-body">
                                                    <smart-input class="basic-input"
                                                                 [placeholder]="'Место хранения документов'"
                                                                 formControlName="linkForDoc"
                                                                 ngModel="{{this.preloadMeetingNotify['link_for_doc']}}"
                                                    >

                                                    </smart-input>
                                                    <smart-button id="buttonWithTooltip1" class="primary very-small">
                                                        Подсказка
                                                    </smart-button>
                                                    <smart-tooltip selector="buttonWithTooltip1" [arrow]="true"
                                                                   [position]="'absolute'">
                                                        Согласно Приказу Минстроя от 28.01.2019 N 44/пр нумерация
                                                        протоколов по отдельному МКД каждый год начинается заново.
                                                        На наш взгляд удобнее нумеровать по формату 1/2018, где 1 -
                                                        номер протокола, 2018 - год
                                                    </smart-tooltip>&nbsp;
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="custom-card">
                                                <div class="card-header">
                                                    <h4 class="label">Инициатор</h4>
                                                </div>
                                                <div class="card-body">
                                                    <smart-input
                                                            class="basic-input"
                                                            drop-down-button-position="right"
                                                            formControlName="meetingInitiator"
                                                            [placeholder]="'Выберете инициатора'"
                                                            [readonly]="true"
                                                            (change)="changeMeetingInitiatorId($event)"
                                                            ngModel="{{this.meetingInitiatorTitle}}"
                                                    >
                                                        <option
                                                                *ngFor="let item of meetingService.meetingInitiator"
                                                                value="{{item.id}}"
                                                        >
                                                            {{item.title}}
                                                        </option>
                                                    </smart-input>
                                                </div>
                                            </div>
                                            <div class="custom-card"
                                                 *ngIf="showInputNameInitiator"
                                            >
                                                <div class="card-header">
                                                    <h4 class="label">Выберете инициатора</h4>
                                                </div>
                                                <div class="card-body">
                                                   <smart-input class="basic-input"
                                                                 formControlName="apartmentInitiatorId"
                                                                 [placeholder] = "this.apartmentOwnerTitle"
                                                                 [dataSource]="this.meetingService.apartmentOwnerSource"
                                                                 drop-down-button-position="right"
                                                                 (change)="changeApartmentInitiatorId($event)"
                                                                 value=""></smart-input>
                                                </div>
                                            </div>
                                            <div class="custom-card">
                                                <div class="card-header">
                                                    <h4 class="label">Место проведения очного этапа голосования</h4>
                                                </div>
                                                <div class="card-body">
                                                    <smart-input class="basic-input"
                                                                 formControlName="placeMeeting"
                                                                 ngModel="{{this.preloadMeetingNotify['place_meeting']}}"
                                                    ></smart-input>
                                                    <!--    &lt;!&ndash;Дебажим значения в форме чтобы глянуть какие ошибки есть&ndash;&gt;
                                                        &lt;!&ndash;  <pre>{{questionCreateForm.get('address').errors | json}}</pre>&ndash;&gt;-->
                                                    <div
                                                            *ngIf="notificationOwnerForm.get('placeMeeting').invalid && notificationOwnerForm.get('placeMeeting').touched"
                                                            class="validation"
                                                    >
                                                        <small *ngIf="notificationOwnerForm.get('placeMeeting').errors.required">
                                                            Поле номер не может быть пустым
                                                        </small>

                                                        <small *ngIf="notificationOwnerForm.get('placeMeeting').errors.minlength">
                                                            Длинна должна быть не
                                                            менее {{notificationOwnerForm.get('placeMeeting').errors.minlength.requiredLength}}
                                                            Сейчас кол-во
                                                            символов {{notificationOwnerForm.get('placeMeeting').errors.minlength.actualLength}}
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="custom-card">
                                                <div class="card-header">
                                                    <h4 class="label">Место, куда будут передаваться решения (бюллетени
                                                        голосования) </h4>
                                                </div>
                                                <div class="card-body">
                                                    <smart-input class="basic-input"
                                                                 [placeholder]="'Местонахождение'"
                                                                 formControlName="placeMeetingSolution"
                                                                 ngModel="{{this.preloadMeetingNotify['place_meeting_solution']}}"
                                                    ></smart-input>
                                                    <!--Дебажим значения в форме чтобы глянуть какие ошибки есть-->
                                                    <!--  <pre>{{meetingCreateForm.get('address').errors | json}}</pre>-->
                                                    <div
                                                            *ngIf="notificationOwnerForm.get('placeMeetingSolution').invalid && notificationOwnerForm.get('placeMeetingSolution').touched"
                                                            class="validation"
                                                    >
                                                        <small *ngIf="notificationOwnerForm.get('placeMeetingSolution').errors.required">
                                                            Поле номер не может быть пустым
                                                        </small>

                                                        <small *ngIf="notificationOwnerForm.get('placeMeetingSolution').errors.minlength">
                                                            Длинна должна быть не
                                                            менее {{notificationOwnerForm.get('placeMeetingSolution').errors.minlength.requiredLength}}
                                                            Сейчас кол-во
                                                            символов {{notificationOwnerForm.get('placeMeetingSolution').errors.minlength.actualLength}}
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="custom-card">
                                                <div class="card-header">
                                                    <h4 class="label">Адрес электронной почты для запроса информации и
                                                        материалов по собранию</h4>
                                                </div>
                                                <div class="card-body">
                                                    <smart-input class="basic-input"
                                                                 [placeholder]="'Email будет в бюллетене'"
                                                                 formControlName="emailMeeting"
                                                                 ngModel="{{this.preloadMeetingNotify['email_meeting']}}"
                                                    >

                                                    </smart-input>
                                                    <smart-button id="buttonWithTooltip2" class="primary very-small">
                                                        Подсказка
                                                    </smart-button>
                                                    <smart-tooltip selector="buttonWithTooltip2" [arrow]="true"
                                                                   [position]="'absolute'">
                                                        Согласно Приказу Минстроя от 28.01.2019 N 44/пр нумерация
                                                        протоколов по отдельному МКД каждый год начинается заново.
                                                        На наш взгляд удобнее нумеровать по формату 1/2018, где 1 -
                                                        номер протокола, 2018 - год
                                                    </smart-tooltip>&nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="custom-row"><br></div>
                                    <div class="custom-row">
                                        <div class="col-md-6">
                                            <div>
                                                <h6 class="label">Дата уведомления - 1</h6>
                                            </div>
                                            <div>
                                                <smart-date-time-picker
                                                    #datetimepickerEnd
                                                    [calendarButton]="true"
                                                    [enableMouseWheelAction]="true"
                                                    [dropDownPosition]="'overlay-top'"
                                                    [spinButtons]="true"
                                                    [spinButtonsPosition]="'left'"
                                                    formControlName="meetingDateNotify"
                                                    [displayKind]="'UTC'"
                                                    [formatString]="'dd-MM-yyyy HH:mm:ss'"
                                                    ngModel="{{this.preloadMeetingNotify['meeting_date_notify']}}"
                                                >
                                                </smart-date-time-picker>
                                            </div>

                                            <div>
                                                <h6 class="label">Начало проведения собрания - 2</h6>
                                            </div>
                                            <div>
                                                <smart-date-time-picker
                                                    #datetimepickerBegin
                                                    [calendarButton]="true"
                                                    [enableMouseWheelAction]="true"
                                                    [dropDownPosition]="'overlay-top'"
                                                    [spinButtons]="true"
                                                    [spinButtonsPosition]="'left'"
                                                    formControlName="meetingDateBegin"
                                                    [displayKind]="'UTC'"
                                                    [formatString]="'dd-MM-yyyy HH:mm:ss'"
                                                    ngModel="{{this.preloadMeetingNotify['meeting_date_begin']}}"
                                                >
                                                </smart-date-time-picker>
                                            </div>

                                            <div>
                                                <h6 class="label">Дата окончания собрания (дата составления протокола) -
                                                    5</h6>
                                            </div>
                                            <div>
                                                <smart-date-time-picker
                                                    #datetimepickerEnd
                                                    [calendarButton]="true"
                                                    [enableMouseWheelAction]="true"
                                                    [dropDownPosition]="'overlay-top'"
                                                    [spinButtons]="true"
                                                    [spinButtonsPosition]="'left'"
                                                    formControlName="meetingDateEnd"
                                                    [displayKind]="'UTC'"
                                                    [formatString]="'dd-MM-yyyy HH:mm:ss'"
                                                    ngModel="{{this.preloadMeetingNotify['meeting_date_end']}}"
                                                >
                                                </smart-date-time-picker>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div>
                                                <h6 class="label">Дата и время проведения очного этапа - 3</h6>
                                            </div>
                                            <div>
                                                <smart-date-time-picker
                                                    #datetimepickerEnd
                                                    [calendarButton]="true"
                                                    [enableMouseWheelAction]="true"
                                                    [dropDownPosition]="'overlay-top'"
                                                    [spinButtons]="true"
                                                    [spinButtonsPosition]="'left'"
                                                    formControlName="meetingBeginOnline"
                                                    [displayKind]="'UTC'"
                                                    [formatString]="'dd-MM-yyyy HH:mm:ss'"
                                                    ngModel="{{this.preloadMeetingNotify['meeting_begin_online']}}"
                                                >
                                                </smart-date-time-picker>
                                            </div>

                                            <div>
                                                <h6 class="label">Дата окончания сбора решений собственников (бюллетеней
                                                    голосования) - 4</h6>
                                            </div>
                                            <div>
                                                <smart-date-time-picker
                                                    #datetimepickerEnd
                                                    [calendarButton]="true"
                                                    [enableMouseWheelAction]="true"
                                                    [dropDownPosition]="'overlay-top'"
                                                    [spinButtons]="true"
                                                    [spinButtonsPosition]="'left'"
                                                    formControlName="meetingEndSolution"
                                                    [displayKind]="'UTC'"
                                                    [formatString]="'dd-MM-yyyy HH:mm:ss'"
                                                    ngModel="{{this.preloadMeetingNotify['meeting_end_solution']}}"
                                                    (onChange)="changeMeetingEndSolution($event)"
                                                >
                                                </smart-date-time-picker>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="custom-row">
                                        <div class="custom-col">
                                            <div class="custom-card">
                                                <div class="card-header">
                                                </div>
                                                <div class="card-body">
                                                    <smart-button type="submit"
                                                                  [disabled]="notificationOwnerForm.invalid">
                                                        Сохранить уведомление
                                                    </smart-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </smart-tab-item>
                        <smart-tab-item
                            (click)="onWindowReady1($event)"
                            label="Уведомление о собрании собственников">

                            <div class="custom-card">
                                <div class="card-body">
                                    <h6>Результаты Голосования (кв,м)</h6>
                                    <br>
                                    <smart-table #table_res_vote id="table_res_vote" [dataSource]="dataSourceVote"
                                                 [paging]="resultVoteDataSettings.paging"
                                                 [sortMode]="resultVoteDataSettings.sortMode"
                                                 [tooltip]="resultVoteDataSettings.tooltip"
                                                 [onInit]="resultVoteDataSettings.onInit"
                                                 [columns]="resultVoteDataSettings.columns"
                                                 [filtering]="resultVoteDataSettings.filtering"
                                                 [filterRow]="resultVoteDataSettings.filterRow"
                                                 [pageIndex]="resultVoteDataSettings.pageIndex"
                                                 [pageSize]="resultVoteDataSettings.pageSize"
                                                 [selection]="resultVoteDataSettings.selection"
                                                 [editing]="resultVoteDataSettings.editing"
                                                 (onCellEndEdit)="onItemAfterEdit($event)"
                                                 (onCellClick)="onCellClick($event)"
                                                 (onChange)="onChangeCheckbox($event)"
                                    ></smart-table>
                                </div>
                            </div>


                           <div class="row">
                               <div class="col-md-6">
                                   <h6>Уведомление о собрании собственников</h6>
                               </div>
                               <div class="col-md-6">
                                   <smart-button (click)="createNotifyMeetingDocument()">Скачать</smart-button>
                               </div>
                           </div>
                           <br>
                           <div class="row">
                               <div class="col-md-6">
                                   <h6>Акт о размещении уведомления</h6>
                               </div>
                               <div class="col-md-6">
                                   <smart-button (click)="createActNotifyDocument()">Скачать</smart-button>
                               </div>
                           </div>
                           <br>
                           <div class="row">
                               <div class="col-md-6">
                                   <h6>Реестр собственников</h6>
                               </div>
                               <div class="col-md-6">
                                   <smart-button (click)="createOwnersDocument()">Скачать</smart-button>
                               </div>
                           </div>
                           <br>
                         <div class="row">
                           <div class="col-md-6">
                             <h6>Реестр собственников принявших участие в собрании</h6>
                           </div>
                           <div class="col-md-6">
                             <smart-button (click)="createOwnersDocumentFact()">Скачать</smart-button>
                           </div>
                         </div>
                         <br>
                           <div class="row">
                               <div class="col-md-6">
                                   <h6>Бюллетени для голосования</h6>
                               </div>
                               <div class="col-md-6">
                                   <smart-button (click)="createOwnersAnketa()">Скачать</smart-button>
                               </div>
                           </div>
                           <br>
                           <div class="row">
                               <div class="col-md-6">
                                   <h6>Протокол голосования</h6>
                               </div>
                               <div class="col-md-6">
                                   <smart-button (click)="createOwnersResults()">Скачать</smart-button>
                               </div>
                           </div>
                       </smart-tab-item>
                        <smart-tab-item label="Провести собрание">
                            <h6>Внести результаты голосования</h6>
                            <div>
                                <form-owner-vote></form-owner-vote>
                            </div>
                        </smart-tab-item>
                    </smart-tabs>
                </div>
            </div>
        </div>
    </div>
</div>



