import {Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ViewEncapsulation, Input} from '@angular/core';
import {MeetingService} from "src/app/pages/meetings/services/meeting.service";
import {Subscription} from "rxjs";
import {BrowserModule} from "@angular/platform-browser";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {DateTimePickerComponent} from "smart-webcomponents-angular/datetimepicker";
import {HouseService} from "../../services/house.service";
import {FileUploadService} from "../../services/file-upload.service";
import {HttpEventType, HttpResponse} from "@angular/common/http";
import {UrlService} from "../../services/url.service";
import {WindowComponent} from "smart-webcomponents-angular/window";
import {ButtonComponent} from "smart-webcomponents-angular/button";
import {TableComponent} from "smart-webcomponents-angular/table";
import {DataAdapter, Smart} from "smart-webcomponents-angular/grid";
import {CommonModule} from "@angular/common";
import {QuestionService} from "../../services/question.service";
import {StorageService} from "../../../../services/storage.service";
import {constLocalStorage, constVote} from "../../../../shared/entity.const";


// @ts-ignore
@Component({
    selector: 'question-owner-vote',
    templateUrl: 'question-owner-vote.component.html',
    providers: [BrowserModule,CommonModule],
    styleUrls: ['question-owner-vote.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class QuestionOwnerVoteComponent implements AfterViewInit, OnDestroy, OnInit {

    // Переменная отвечающая за подписку
    subscription1$: Subscription;
    subscription2$: Subscription;
    subscription3$: Subscription;
    subscriptions: Subscription[] = [];
    mSubRouter: Subscription;
    // Переменная отвечающая за подписку
    mSub: Subscription

    isLoaded:boolean=false;

    isUploadingOwner = false; // Загружены ли пользователи
    meetingId: any
    ownerId: any

    selectedFiles?: FileList;
    currentFile?: File;
    progress = 0;
    message = '';

    // Переменные отвечающие за чекбоксы
    selectedItems: Array<any> = [];
    agreeDeleteCheckbox: boolean = false; // Флаг кнопка согласие в модальном окне
    isDisabledButtonDeleteAll: boolean = true; // Изначально отключено. Кнопка удалить выбранные

    agreeDeleteAllOwners: boolean = false; // Флаг кнопка согласие в модальном окне
    isDisabledButtonAgreeAllOwners: boolean = false; // Изначально включено. Кнопка удалить всех собственников

    selectFile(event: any): void {
        this.selectedFiles = event.target.files;
    }

    //Флаг, который указывает весь html данного компонента отображать
    isCurrentMeeting:boolean = false

    constructor(
        private meetingService: MeetingService,
        private houseService: HouseService,
        private router: Router,
        private urlService: UrlService,
        private activeRouter: ActivatedRoute, // Отображает текущий роут компонента
        private storageService: StorageService,
        public questionService: QuestionService,
    ) {
        Object.assign(QuestionOwnerVoteComponent, {
            meetingService,
        });
    }

    @ViewChild('button', {read: ButtonComponent, static: false}) button!: ButtonComponent;
    @ViewChild('modalWindow', {read: WindowComponent, static: false}) modalWindow: WindowComponent;
    @ViewChild('formWindow', {read: WindowComponent, static: false}) formWindow: WindowComponent;
    @ViewChild('formWindowDeleteAll', {read: WindowComponent, static: false}) formWindowDeleteAll: WindowComponent;
    @ViewChild('tableQuestionVote', {read: TableComponent, static: false}) tableWithButtons!: TableComponent;


    /**
     * Запускается 1 на странице перед выводом визуала
     */
    ngOnInit() {
        console.log('Запустился ngOnInit()');
        // Получаем параметры из url
        this.activeRouter.params.subscribe((params: Params) => {
            this.urlService.urlParams = params
            this.meetingId = params.meetingId
            this.ownerId = params.ownerId
        })
        if (this.router.url.includes('current-meeting')) {
            this.isCurrentMeeting = true
        }
        // Получаем все вопросы по собранию
        this.getQuestionsMeeting();
    }


    /**
     * Очищаем подписку на стрим чтобы не было утечек по памяти
     */
    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());

        if (this.mSubRouter) {
            this.mSubRouter.unsubscribe();
        }
    }

    /**
     * Запускается 3 - им
     */
    init(): void {
        // Переименовали кнопки. Пред загрузка перевода на русский язык
       this.tableWithButtons.messages = {
            'en': {
                'filterPlaceholder': 'Поиск',
            },
        };
        this.tableWithButtons.locale = 'en';
    }

    /**
     * Св-во нужно для инициализации. Для интерфейса. Нужен для норм работы
     */
    onInit(): void {
        console.log('Запустился onInit()');
    };

    /**
     * Запускается 2 на странице после ngOnInit()
     */
    ngAfterViewInit() {
        console.log('Loading ngAfterViewInit form-update-meeting');
        // грузим метод чтобы загрузить that.fileupload.messages переводы названия кнопок
        this.init();
        // Заменяет иконки после прорисовки компонента на нужные
        const windowWithFeather = window as any;
        windowWithFeather.feather.replace();
        console.log('Запустился  ngAfterViewInit()');
        this.documentReady();
        window.onload = function () {
            console.log(' window.onload ');
            /*      const elems = document.body.querySelector('.ggggg1')
                  // @ts-ignore
                  elems.addEventListener('click', function(event) {
                    event.preventDefault();
                    alert('Дорогу осилит идущий');
                  })*/
        }
    }

    /**
     * Отмечаем выбранные чекбоксы сохраненные в localStorage. Подгружаем их через 2,5с после загрузки страницы
     */
    documentReady(): void {
        if (this.storageService.getSomeVariable(constLocalStorage.ROW_VOTE_IDS + '?' + this.urlService.urlParams.meetingId) != undefined
            && this.storageService.getSomeVariable(constLocalStorage.ROW_VOTE_IDS + '?' + this.urlService.urlParams.meetingId).length > 0) {
            function sleep(ms: number) {
                return new Promise(resolve => setTimeout(resolve, ms));
            }
            const delayedGreeting = async () => {
                await sleep(2500);
                this.storageService.getSomeVariable(constLocalStorage.ROW_VOTE_IDS + '?' + this.urlService.urlParams.meetingId).forEach((item: string, key: any) => {
                    document.querySelector('tr[row-id="' + item + '"]').firstElementChild.classList.add("selected");
                })
            }
            delayedGreeting().then(r => {});
        }
    }

    onWindowReady(event: any): void {
        this.init();
    }

    onButtonClick(event: any): void {
        console.log('sdsds');
        this.formWindow.opened ? this.formWindow.close() : this.formWindow.open();
    }

    onButtonClick2(event: any): void {
        this.formWindow.close();
    }

    /**
     * Получить все вопросы по собранию
     */
    getQuestionsMeeting(): void {
        const params: any[any] = [];
        params.meetingId = this.urlService.urlParams.meetingId;
        params.userId = this.storageService.getUser();

        // Параметр houseId берется из роутинга из url. В роутинге есть параметр 'houses/:houseId/meetings'
        this.mSub = this.questionService.getQuestionsMeeting(params)
            .subscribe(questions => {
                // Заполнили данными
                this.dataAdapter.dataSource = questions.results;
            });
    }

    bottomRightChange():void {
        console.log('yes')
    }
    bottomRightChange1():void {
        console.log('Now')
    }
    /**
     **  Провайдер данных обозначаем какие поля в нем будут
     */
    dataAdapter: DataAdapter = new Smart.DataAdapter({
        dataSource: [],
        dataFields: [
            'id: number',
            'name: string',
            'text_message: string',
         ],
    })

    dataSource = this.dataAdapter;

    /**
     * Настройки таблицы
     */
    questionsDataSettings = {
        /**
         * Св-во нужно для инициализации. Для интерфейса. Нужен для норм работы
         */

        onInit: () => {
        },

        behavior: {columnResizeMode: 'growAndShrink'},

        // Параметры таблицы
        pageIndex: 0,
        pageSize: "10",
        filtering: true,
        paging: true,
        sortMode: 'many',
        filterRow: false,
        tooltip: true,
        selection: false,
        editing: false,
        editMode: 'row',
        keyboardNavigation: true,

        // Колонки в таблице
        columns: [
            {
                label: 'id', dataField: 'id', dataType: 'number', responsivePriority: 3, width: 50
            },
            {label: 'Вопросы', dataField: 'text_message', dataType: 'string', allowEdit: true, responsivePriority: 3},
            /*           {
                           label: 'Дата собрания', dataField: 'meetingDateBegin', allowEdit: false, dataType: 'string', responsivePriority: 3,
                           formatFunction: (value:any) => {
                               new DatePipe('en-US').transform(value.value, 'dd-MM-yyyy');
                               value.template = new DatePipe('en-US').transform(value.value, 'dd.MM.yyyy');
                           }
                       },*/
   /*         {
                label: '', dataField: 'actionVote1', allowEdit: false, width: 150,
                formatFunction: function (settings: any) {
                    settings.template = '<div class="actions-container">' +
                        '<div class="options-container">' +
                        '<smart-radio-button (onChange)=this.bottomRightChange()" [value]="yes">ЗА</smart-radio-button>' +
                        '<smart-radio-button (onChange)=bottomRightChange1() [checked]="true">ПРОТИВ</smart-radio-button>' +
                        '<smart-radio-button [disabled]="true">ВОЗДЕРЖАЛСЯ</smart-radio-button>' +
                        '</div>' +
                        '</div></div>';
                }
            },*/
/*            {
                label: '', dataField: 'actionYes', allowEdit: false, width: 150,
                formatFunction: function (settings: any) {
                    settings.template = '<smart-radio-button enable-container-click group-name="action-vote" class="small-button outlined primary">ЗА</smart-radio-button>';
                }
            },
            {
                label: '', dataField: 'actionNo', allowEdit: false, width: 150,
                formatFunction: function (settings: any) {
                    settings.template = '<smart-radio-button enable-container-click group-name="action-vote">ПРОТИВ</smart-radio-button>';
                }
            },
            {
                label: '', dataField: 'actionVote', allowEdit: false, width: 150,
                formatFunction: function (settings: any) {
                    settings.template = '<smart-radio-button enable-container-click group-name="action-vote">ВОЗДЕРЖАЛСЯ</smart-radio-button>';
                }
            },*/
            {
                label: '', dataField: 'actionYes', allowEdit: false, width: 150,
                formatFunction: function (settings: any) {
                    settings.template = '<div class="actions-container"><smart-button class="small-button outlined primary">ЗА</smart-button></div>';
                }
            },
            {
                label: '', dataField: 'actionNo', allowEdit: false, width: 150,
                formatFunction: function (settings: any) {
                    settings.template = '<div class="actions-container"><smart-button class="small-button outlined primary">ПРОТИВ</smart-button></div>';
                }
            },
            {
                label: '', dataField: 'actionUnknown', allowEdit: false, width: 150,
                formatFunction: function (settings: any) {
                    settings.template = '<div class="actions-container"><smart-button class="small-button outlined primary">ВОЗДЕРЖАЛСЯ</smart-button></div>';
                }
            },
/*            {
                label: '', dataField: 'actionNo', allowEdit: false, width: 150,
                formatFunction: function (settings: any) {
                    settings.template = '<div class="actions-container"><smart-check-box id="topLeft">ПРОТИВ</smart-check-box></div>';
                }
            },
            {
                label: '', dataField: 'actionUnknow', allowEdit: false, width: 150,
                formatFunction: function (settings: any) {
                    settings.template = '<div class="actions-container"><smart-check-box id="topLeft">ВОЗДЕРЖАЛСЯ</smart-check-box></div>';
                }
            },*/

     /*       {
                label: '', dataField: 'actionDelete', allowEdit: false, width: 50,
                formatFunction: function (settings: any) {
                    settings.template =
                        '<a href="javascript:;" class="nav-link rounded action-icon">' +
                        '<i class="material-icons">delete_forever</i>' + '</a>';
                }
            },*/
        ],
    }
    gg () {
        console.log('gg')
    }
    /**
     * Вызывается обработчик события и апдейтится поле когда закончили редактирование в колонке
     * @param event
     */
    onItemAfterEdit(event: any) {
        this.mSub = this.houseService.updateOwnerValue(event.detail).subscribe(owner => {
            // Получили обновленные данные и записали в this.dataAdapter.dataSource
            this.getQuestionsMeeting();
            // Скинули все чекбоксы
            this.tableWithButtons.clearSelection();
        });
    }

    /**
     * Вызывается обработчик события когда кликнули по иконкам или чекбоксу
     * @param event
     */
    onCellClick(event: { detail: { dataField: string; row: { id: number; }; id: string; }; target: any; }) {
        let bottomUnknown = document.querySelector(('tr[row-id="' + event.detail.id + '"]')).children[4].querySelector('smart-button');
        let bottomYes = document.querySelector(('tr[row-id="' + event.detail.id + '"]')).children[2].querySelector('smart-button');
        let bottomNO = document.querySelector(('tr[row-id="' + event.detail.id + '"]')).children[3].querySelector('smart-button');

        const params: any[any] = [];
        params['meetingId'] = Number(this.meetingId);
        params['ownerId'] = Number(this.ownerId);
        params['questionId'] = event.detail.row.id;
        if (event.detail.dataField === 'actionUnknown') {
            this.urlService.urlParams = event.detail.row.id;
            this.selectedItems.push(event.detail.row.id);
            console.log("actionUnknown")
            params['vote'] = constVote.VOTE_UNKNOWN;
            //Подкрашиваем кнопку ВОЗЖЕРЖАЛСЯ
            if(bottomUnknown.classList.contains('vote-click-button')) {
                bottomUnknown.classList.remove('vote-click-button')
            } else {
                bottomUnknown.classList.add('vote-click-button')
            }
            bottomYes.classList.remove('vote-click-button')
            bottomNO.classList.remove('vote-click-button')
            this.updateMeetingVote(params)
        }

        //Клик по иконке delete
        if (event.detail.dataField === 'actionNo') {
            console.log("actionNo")
            params['vote'] = constVote.VOTE_NO;
            //Подкрашиваем кнопку
            if(bottomNO.classList.contains('vote-click-button')) {
                bottomNO.classList.remove('vote-click-button')
            } else {
                bottomNO.classList.add('vote-click-button')
            }
            bottomUnknown.classList.remove('vote-click-button')
            bottomYes.classList.remove('vote-click-button')
            this.updateMeetingVote(params)
        }
        if (event.detail.dataField === 'actionYes') {
            console.log("actionYes")
            params['vote'] = constVote.VOTE_YES;
            //Подкрашиваем кнопку
            if(bottomYes.classList.contains('vote-click-button')) {
                bottomYes.classList.remove('vote-click-button')
            } else {
                bottomYes.classList.add('vote-click-button')
            }
            bottomUnknown.classList.remove('vote-click-button')
            bottomNO.classList.remove('vote-click-button')
            this.updateMeetingVote(params)
        }

        //Клик по чекбоксу
        if (this.selectedItems === undefined || this.selectedItems.length == 0) {
            this.selectedItems.push(event.detail.row.id);
        } else {
            // Если еще нет значения в массиве то добавляем
            if (!this.selectedItems.includes(event.detail.row.id)) {
                //console.log('еще нет значения');
                this.selectedItems.push(event.detail.row.id);
            } else {
                //console.log('такое есть значения'); Удаляем из массива selectedItems
                const index = this.selectedItems.indexOf(event.detail.row.id, 0);
                //console.log('index - ' + index);
                if (index > -1) {
                    this.selectedItems.splice(index, 1);
                }
            }
        }
    }

    /**
     * Update голос. Голос - ВОЗДЕРЖАЛСЯ/ЗА/ПРОТИВ
     * @param params
     */
    updateMeetingVote (params: any) {
        this.mSub = this.questionService.updateMeetingVote(params).subscribe()
    }


    /**
     * Клик по выбрать все чекбоксы. Записываем данные в this.selectedItems
     * @param event
     */
    onChangeCheckbox(event: { detail: { dataField: string; row: { id: number; }; id: string; }; target: any; type: string }) {
        let allCheckboxSelected = document.querySelectorAll('th.selected');
        let sameCheckboxSelected = document.querySelectorAll('th.indeterminate');

        if (allCheckboxSelected.length > 0 || sameCheckboxSelected.length > 0) {
            this.isDisabledButtonDeleteAll = false;

            let checked = document.querySelectorAll('tr[aria-selected="true"]');
            checked.forEach((item, key) => {

                let val = item.querySelector('[data-field="id"]').innerHTML;
                // Если еще нет значения в массиве то добавляем
                if (!this.selectedItems.includes(Number(val))) {
                    //console.log('еще нет значения');
                    this.selectedItems.push(Number(val));
                }
            })
        } else {
            this.selectedItems = [];
            this.isDisabledButtonDeleteAll = true;
        }
    }

    /**
     * Удалить всех собственников
     */
    deleteAllOwners(event: any): void {
        // Открываем модальное окно
        const target: HTMLElement = event.target as HTMLElement,
            modal = this.formWindowDeleteAll.nativeElement;
        target.closest('#formWindowDeleteAll') === modal ? modal.open() : modal.open();

        // Навешиваем обработчики событий.
        const cancelButtonformWindowDeleteAll = (event: any): void => {
            const target: HTMLElement = event.target as HTMLElement,
                modal = this.formWindowDeleteAll.nativeElement;
            target.closest('#formWindowDeleteAll') === modal ? modal.close() : modal.close();

            this.agreeDeleteAllOwners = false;
        }
        // Подтверждение
        const agreeHandlerformWindowDeleteAll = (event: any): void => {
            const target: HTMLElement = event.target as HTMLElement,
                modal = this.formWindowDeleteAll.nativeElement;
            target.closest('#formWindowDeleteAll') === modal ? modal.close() : modal.close();
            this.agreeDeleteAllOwners = true;
            this.isDisabledButtonAgreeAllOwners = true;

            // Если есть флаг и клик/событие (а то этот код не успевал выполнятся)
            if (this.agreeDeleteAllOwners && event) {
                const params: any[any] = [];
                params['house_id'] = this.urlService.urlParams.houseId;
                // Закрываем модальное окно
                target.closest('#formWindowDeleteAll') === modal ? modal.close() : modal.close();
                this.mSub = this.houseService.deleteAllOwners(params)
                    .subscribe(houses => {
                        // Скинули все чекбоксы
                        this.tableWithButtons.clearSelection();
                    });
                this.agreeDeleteAllOwners = false;
                this.ngOnInit()
                //Перезагружаем текущую страницу
                // location.reload()
            }

        }
        document.getElementById('сancelButtonformWindowDeleteAll').addEventListener('click', cancelButtonformWindowDeleteAll);
        document.getElementById('agreeHandlerformWindowDeleteAll').addEventListener('click', agreeHandlerformWindowDeleteAll);
        // Конец навешиваем обработчики событий.

        // this.router.navigate(['houses/']);

        this.agreeDeleteAllOwners = false;
    }

    /**
     * Создать нового владельца
     */
    addOwners(event: any): void {
        this.router.navigate(['houses/' + this.urlService.urlParams.houseId + '/create-owner']).then();
    }

    /**
     * Удалить владельца
     */
    deleteOwner(id: number): void {
        const params: any[any] = [];
        params['id'] = id;
        params['house_id'] = this.urlService.urlParams.houseId;
        this.mSub = this.houseService.deleteOwner(params)
            .subscribe(houses => {
                // Заполнили данными
                // this.dataAdapter.dataSource = meetings.result;
            });
    }

    /**
     * Кнопка Удалить все выбранные чекбоксы. Http запрос на сервер и визуал
     */
    deleteSelectedCheckBox(event: any): void {
        // Навешиваем обработчики событий.
        const cancelHandler = (event: any): void => {
            const target: HTMLElement = event.target as HTMLElement,
                modal = this.formWindow.nativeElement;
            target.closest('#formWindow') === modal ? modal.close() : modal.close();

            this.agreeDeleteCheckbox = false;
        }
        // Подтверждение
        const agreeHandler = (event: any): void => {
            const target: HTMLElement = event.target as HTMLElement,
                modal = this.formWindow.nativeElement;
            target.closest('#formWindow') === modal ? modal.close() : modal.close();
            this.agreeDeleteCheckbox = true;
            this.isDisabledButtonDeleteAll = true;
            this.deleteSelectedCheckBox(event)
        }

        document.getElementById('сancelButton').addEventListener('click', cancelHandler);

        document.getElementById('agreeHandler').addEventListener('click', agreeHandler);
        // Конец навешиваем обработчики событий.

        let sameCheckboxSelected = document.querySelectorAll('th.indeterminate');
        let allCheckboxSelected = document.querySelectorAll('th.selected');
        const target: HTMLElement = event.target as HTMLElement,
            modal = this.formWindow.nativeElement;
        if (allCheckboxSelected.length > 0 || sameCheckboxSelected.length > 0) {
            target.closest('#formWindow') === modal ? modal.open() : modal.open();
            this.isDisabledButtonDeleteAll = false;
        } else {
            this.selectedItems = [];
            this.isDisabledButtonDeleteAll = true;
        }

        if (this.agreeDeleteCheckbox) {
            const params: any[any] = [];
            params['owner_ids'] =  this.selectedItems;
            params['house_id'] = this.urlService.urlParams.houseId;

            target.closest('#formWindow') === modal ? modal.close() : modal.close();
            this.mSub = this.houseService.deleteOwners(params)
                .subscribe(houses => {
                    // Обновили таблицу
                    this.getQuestionsMeeting();
                    // Скинули все чекбоксы
                    this.tableWithButtons.clearSelection();
                });
            this.selectedItems = [];
            this.agreeDeleteCheckbox = false;
            // this.ngOnInit()
            //Перезагружаем текущую страницу
            location.reload()
        }
        // this.router.navigate(['houses/']);

        this.agreeDeleteCheckbox = false;
    }

}
