<h6>Внести результаты голосования</h6>
<!--    <div class="custom-row">
        <div class="tabs-header custom-col"><h4>Выбранные вопросы</h4></div>
    </div>-->
<!--<smart-button (click)="addQuestion()">Новый вопрос</smart-button>
<smart-button (click)="copyAnotherMeeting()">Скопировать из другого собрания</smart-button>
<smart-button (click)="getStandartQuestion()">Шаблоны</smart-button>
<smart-button (click)="getMyQuestion()">Избранные вопросы</smart-button>-->

<div class="custom-row">
    <div class="tabs-header custom-col"><!--<h4>Выбранные вопросы</h4>--></div>
</div>
<br>
<smart-button #button2 (onClick)="deleteSelectedCheckBox($event)"
              [disabled]=this.isDisabledButtonDeleteAll>
    Удалить выбранныеы
</smart-button>
<div class="custom-card">
    <div class="card-body">
        <smart-table #tableQuestionVote id="table" [dataSource]="dataSource"
                     [paging]="questionsDataSettings.paging"
                     [sortMode]="questionsDataSettings.sortMode"
                     [tooltip]="questionsDataSettings.tooltip"
                     [onInit]="questionsDataSettings.onInit"
                     [columns]="questionsDataSettings.columns"
                     [filtering]="questionsDataSettings.filtering"
                     [filterRow]="questionsDataSettings.filterRow"
                     [pageIndex]="questionsDataSettings.pageIndex"
                     [pageSize]="questionsDataSettings.pageSize"
                     [selection]="questionsDataSettings.selection"
                     [editing]="questionsDataSettings.editing"
                     (onCellEndEdit)="onItemAfterEdit($event)"
                     (onCellClick)="onCellClick($event)"
                     (onChange)="onChangeCheckbox($event)"
        ></smart-table>
    </div>
</div>
<smart-window #formWindow id="formWindow" [footerTemplate]="'footerTemplate'"
              [opened]="false" [label]="'Внимание!'"
              (onReady)="onWindowReady($event)">
    <div id="article">
        <section>
            <h3>Уверены что хотите удалить выделенные вопросы?</h3>
            <p>При нажатии на кнопку все выделенные вопросы удаляться их нельзя будет
                вернуть </p>
        </section>
    </div>
</smart-window>
<template id='footerTemplate'>
    <smart-button id="сancelButton">Нет</smart-button>
    <smart-button id="agreeHandler">Да</smart-button>
</template>
