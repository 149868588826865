import {Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ViewEncapsulation, Input} from '@angular/core';
import {MeetingService} from "src/app/pages/meetings/services/meeting.service";
import {count, Subscription} from "rxjs";
import {BrowserModule} from "@angular/platform-browser";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DateTimePickerComponent} from "smart-webcomponents-angular/datetimepicker";
import {HouseService} from "../services/house.service";
import {UrlService} from "../services/url.service";
import {DataAdapter, Smart} from "smart-webcomponents-angular/grid";
import {CommonModule, DatePipe} from "@angular/common";
import {QuestionService} from "../services/question.service";
import {ButtonComponent} from "smart-webcomponents-angular/button";
import {WindowComponent} from "smart-webcomponents-angular/window";
import {TableComponent} from "smart-webcomponents-angular/table";
import {StorageService} from "../../../services/storage.service";
import {TooltipComponent} from "smart-webcomponents-angular/tooltip";
import {constVoteSolution} from "../../../shared/entity.const";


// @ts-ignore
@Component({
    selector: 'current-meeting',
    templateUrl: 'current-meeting.component.html',
    providers: [BrowserModule, CommonModule],
    styleUrls: ['current-meeting.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CurrentMeetingComponent implements AfterViewInit, OnDestroy, OnInit {

    @ViewChild('datetimepickerBegin', {
        read: DateTimePickerComponent,
        static: false
    }) datetimepickerBegin!: DateTimePickerComponent;
    @ViewChild('datetimepickerEnd', {
        read: DateTimePickerComponent,
        static: false,
    }) datetimepickerEnd!: DateTimePickerComponent;

    @ViewChild('button', {read: ButtonComponent, static: false}) button!: ButtonComponent;
    @ViewChild('tooltip', {read: TooltipComponent, static: false}) tooltip!: TooltipComponent;
    @ViewChild('modalWindow', {read: WindowComponent, static: false}) modalWindow: WindowComponent;
    @ViewChild('formWindow', {read: WindowComponent, static: false}) formWindow: WindowComponent;
    @ViewChild('table', {read: TableComponent, static: false}) tableWithButtons!: TableComponent;
    @ViewChild('table_res_vote', {read: TableComponent, static: false}) tableWithButtonsVote!: TableComponent;

    // Переменная отвечающая за подписку
    subscription1$: Subscription;
    subscription2$: Subscription;
    subscription3$: Subscription;
    subscription4$: Subscription;
    subscription5$: Subscription;
    subscription6$: Subscription;
    subscriptions: Subscription[] = [];

    mSubRouter: Subscription

    //Переменная отвечающая за подписку
    mSub: Subscription
    notificationOwnerForm: FormGroup

    selectedItems: Array<any> = []; // Выбранные чекбоксы Id
    agreeDeleteCheckbox: boolean = false; // Флаг кнопка согласие в модальном окне
    isDisabledButtonDeleteAll: boolean = true; // Изначально отключено. Кнопка удалить все

    //Переменные содержат id с инпутов (содержит выбранное значение из выпадающего списка)
    meetingInitiatorId: any
    apartmentOwnerId: any
    apartmentOwner: any
    apartmentOwnerTitle: any
    apartmentInitiatorId: any
    meetingOnlineId: any
    meetingPeopleId: any
    meetingQueueId: any
    meetingPeopleTitle: any
    meetingInitiatorTitle: any
    meetingOnlineTitle: any
    meetingQueueTitle: any
    meetingEndSolution: any
    //Данные по предзагрузке для уведомления
    preloadMeetingNotify: any;

    isLoaded: boolean = false;

    // Флаг показа дополнительных input
    showInputNameInitiator: boolean = false

    private static questionService: QuestionService;

    constructor(
        public meetingService: MeetingService,
        public questionService: QuestionService,
        public houseService: HouseService,
        private router: Router,
        private urlService: UrlService,
        private activeRouter: ActivatedRoute, // Отображает текущий роут компонента
        private storageService: StorageService
    ) {
        Object.assign(CurrentMeetingComponent, {
            meetingService,
            questionService,
        });
    }

    /**
     * Запускается 1 на странице
     */
    ngOnInit() {
        // Получаем параметры из url
        this.activeRouter.params.subscribe((params: Params) => {
            this.urlService.urlParams = params
        })
        // Получаем данные через предзагрузку используем resolver
        // meetingInitiatorPreload - имя ресолвера используется в компоненте app-routing.module.ts
        this.subscription1$ = this.activeRouter.data.subscribe(
            (response: any) => {
                this.meetingService.meetingInitiator = response.meetingInitiatorPreload.results;
            }
        )
        this.subscription2$ = this.activeRouter.data.subscribe(
            (response: any) => {
                this.meetingService.meetingOnline = response.meetingOnlinePreload.results;
            }
        )
        this.subscription3$ = this.activeRouter.data.subscribe(
            (response: any) => {
                this.meetingService.meetingQueue = response.meetingQueuePreload.results;
            }
        )
        this.subscription4$ = this.activeRouter.data.subscribe(
            (response: any) => {
                this.meetingService.meetingPeople = response.meetingPeoplePreload.results;
            }
        )
        this.subscription5$ = this.activeRouter.data.subscribe(
            (response: any) => {
                this.preloadMeetingNotify = response.meetingNotifyPreload.results;
            }
        )
        this.subscription6$ = this.activeRouter.data.subscribe(
            (response: any) => {
                this.apartmentOwner = response.apartmentOwnerPreload.results;
            }
        )



        //ПРЕДЗАГРУЗКА INPUT. Если есть уведомление у текущего собрания мы подставляем в инпуты значения из бд.
        //Если нет, то мы ставим дефолтные
        if (this.preloadMeetingNotify.id == 0) {
            //Данных нет в бд
            console.log('Данных нет в бд')
            this.preloadMeetingNotify['meeting_begin_online'] = new Date().toISOString()
            this.preloadMeetingNotify['meeting_date_begin'] = new Date().toISOString()
            this.preloadMeetingNotify['meeting_date_end'] = new Date().toISOString()
            this.preloadMeetingNotify['meeting_date_notify'] = new Date().toISOString()
            this.preloadMeetingNotify['meeting_end_solution'] = new Date().toISOString()
        } else {
            //Показываем инпут собственника
            if (this.preloadMeetingNotify.apartment_owner_id) {
                this.showInputNameInitiator = true

                if (this.apartmentOwner.owners) {
                    this.apartmentOwner.owners.forEach((item: { name: any; id: any }) => {
                        this.meetingService.apartmentOwnerSource.push(item.name)
                           if (item.id === this.preloadMeetingNotify.apartment_owner_id) {
                               this.apartmentOwnerTitle = item.name
                           }
                    })
                }

            }
            //Подготавливаем данные для input. Данные есть в бд
            this.meetingService.meetingPeople.forEach((item: any) => {
                if (item.id === this.preloadMeetingNotify['meeting_people_id']) {
                    this.meetingPeopleTitle = item.title
                }
            });
            this.meetingService.meetingInitiator.forEach((item: any) => {
                if (item.id === this.preloadMeetingNotify['meeting_initiator_id']) {
                    this.meetingInitiatorTitle = item.title
                }
            });
            this.meetingService.meetingOnline.forEach((item: any) => {
                if (item.id === this.preloadMeetingNotify['meeting_online_id']) {
                    this.meetingOnlineTitle = item.title
                }
            });
            this.meetingService.meetingQueue.forEach((item: any) => {
                if (item.id === this.preloadMeetingNotify['meeting_queue_id']) {
                    this.meetingQueueTitle = item.title
                }
            });

        }
        // Добавляем все подписки в массив чтобы потом от них отписаться
        this.subscriptions.push(this.subscription1$);
        this.subscriptions.push(this.subscription2$);
        this.subscriptions.push(this.subscription3$);
        this.subscriptions.push(this.subscription4$);
        this.subscriptions.push(this.subscription5$);

        // Получаем начальные данные по вопросам
        this.getQuestionsMeeting();

        // Инициализируем форму и поля
        this.notificationOwnerForm = new FormGroup<any>({
            numberProtocol: new FormControl('', [Validators.required, Validators.minLength(1)]),
            meetingInitiator: new FormControl('', [Validators.required]),
            apartmentInitiatorId: new FormControl('', []),
            meetingOnline: new FormControl('', [Validators.required]),
            meetingQueue: new FormControl('', [Validators.required]),
            meetingPeople: new FormControl('', [Validators.required]),
            placeMeeting: new FormControl('', [Validators.required, Validators.minLength(6)]),
            placeMeetingSolution: new FormControl('', [Validators.required, Validators.minLength(6)]),
            emailMeeting: new FormControl('', [Validators.required, Validators.email]),
            meetingDateBegin: new FormControl('', [Validators.required]),
            meetingDateEnd: new FormControl('', [Validators.required]),
            meetingDateNotify: new FormControl('', [Validators.required]),
            meetingBeginOnline: new FormControl('', [Validators.required]),
            meetingEndSolution: new FormControl('', [Validators.required]),
            linkForDoc: new FormControl('', []),
        })

        // Получаем начальные данные по голосованию
        this.getVoteResults();
        // console.log('this.preloadMeetingNotify')
        // console.log(this.preloadMeetingNotify)
    }

    /**
     * Получить все вопросы по собранию
     */
    getQuestionsMeeting(): void {
        const params: any[any] = [];
        params.meetingId = this.urlService.urlParams.meetingId;
        params.userId = this.storageService.getUser();

        // Параметр houseId берется из роутинга из url. В роутинге есть параметр 'houses/:houseId/meetings'
        this.mSub = this.questionService.getQuestionsMeeting(params)
            .subscribe(questions => {
                // Заполнили данными
                this.dataAdapter.dataSource = questions.results;
            });
    }

    /**
     * Получить результаты голосования по собранию
     */
    getVoteResults(): void {
        const params: any[any] = [];
        params.meetingId = this.urlService.urlParams.meetingId;
        params.userId = this.storageService.getUser();

        // Параметр houseId берется из роутинга из url. В роутинге есть параметр 'houses/:houseId/meetings'
        this.mSub = this.questionService.getVoteResults(params)
            .subscribe(vote => {
                // Заполнили данными
                this.dataAdapterVote.dataSource = vote.results;
            });
    }


    /**
     * Очищаем подписку на стрим чтобы не было утечек по памяти
     */
    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());

        if (this.mSubRouter) {
            this.mSubRouter.unsubscribe();
        }
    }

    /**
     * Запускается 3 - им
     */
    init(): void {
        console.log(this.isLoaded)
    }

    /**
     * Св-во нужно для инициализации. Для интерфейса. Нужен для норм работы
     */
    onInit(): void {
        console.log('Запустился onInit()');
    };

    onWindowReady(event: any): void {
        this.init();
    }

    onWindowReady1(event: any): void {
        this.init();
    }

    //При клике на табу
    onTabClick(tab: any, $event: Event) {
        console.log('onTabClick');
        /*        const target = $event.target as HTMLElement;

                if (target.closest('.next')) {
                    tab.select(tab.selectedIndex! + 1);
                } else if (target.closest('.prev')) {
                    tab.select(tab.selectedIndex! - 1);
                }*/

    }

    /*    onTabsChange(tab: any, $event: CustomEvent) {
            console.log('onTabsChange');
            const index = $event.detail.index;

            tab.querySelector(`.smart-tab-label-container:nth-child(${index + 1})`)!.classList.remove('unreached');

        }*/

    /**
     * Запускается 2 на странице после ngOnInit().
     */
    ngAfterViewInit() {
        console.log('Loading ngAfterViewInit current-create-meeting');
    }

    /**
     **  Провайдер данных обозначаем какие поля в нем будут
     */
    dataAdapter: DataAdapter = new Smart.DataAdapter({
        dataSource: [],
        dataFields: [
            'id: number',
            'priority: string',
            'solution_id: any',
            'questionDateBegin: string',
            'text_message: string',
            'text_owner: string',
        ],
    })

    dataSource = this.dataAdapter;

    /**
     * Настройки таблицы
     */
    questionsDataSettings = {
        /**
         * Св-во нужно для инициализации. Для интерфейса. Нужен для норм работы
         */
        onInit: () => {
        },
        currentUrl: this.router.url,
        behavior: {columnResizeMode: 'growAndShrink'},
        questionService: CurrentMeetingComponent.questionService,
        // Пример запроса по ajax. Проблема fetch делается несколько раз
        /*    dataSource: new window.Smart.DataAdapter({
              virtualDataSourceCache: false,
              virtualDataSource:  function (resultCallbackFunction: ResultCallbackFunction) {
                fetch('/api/v1/product/getMeetings/')
                  .then(response => response.json())
                  .then(json => {
                        const data: [] = json.results.map((result: {
                          id: number;
                          address: any;
                          meetingDate: string
                          initiator: string;
                          ownerCount: number;
                          questionCount: number;
                          meetingStatus: number;
                          paymentStatus: number;
                          comment: string;

                        }) => {
                      return {
                        id: result.id,
                        meetingDate: result.meetingDate,
                        address: result.address,
                        initiator: result.initiator,
                        ownerCount: result.ownerCount,
                        questionCount: result.questionCount,
                        meetingStatus: result.meetingStatus,
                        paymentStatus: result.paymentStatus,
                        comment: result.comment,
                      };
                    });
                    resultCallbackFunction({
                      dataSource: data
                    });
                  });
              },
              dataFields: [
                'id: number',
                'address: string',
                'meetingDate: string',
                'initiator: string',
                'ownerCount: number',
                'questionCount: number',
                'meetingStatus: number',
                'paymentStatus: number',
                'comment:       string',
                'createDate:    string',
                'updateDate: string'
              ],
            }),*/

        // Параметры таблицы
        pageIndex: 0,
        pageSize: "10",
        filtering: true,
        paging: true,
        sortMode: 'many',
        filterRow: false,
        tooltip: true,
        selection: true,
        editing: true,
        editMode: 'row',
        keyboardNavigation: true,

        // Колонки в таблице
        columns: [
            {
                label: 'id', dataField: 'id', dataType: 'number', responsivePriority: 3, width: 50
            },
            /*          {
                          label: 'Id', dataField: 'actionId', dataType: 'number', responsivePriority: 3, width: 50,
                          formatFunction: function (settings: any) {
                              settings.template =
                                  '<a href="javascript:;" class="nav-link rounded action-icon">' + settings.data.id + '</a>';
                          }
                      },*/
            {
                label: 'priority', dataField: 'priority', dataType: 'number', responsivePriority: 3, width: 100
            },
            {
                label: 'Формулировка для уведомления',
                dataField: 'text_message',
                dataType: 'string',
                responsivePriority: 3,
                width: 200
            },
            {
                label: 'Формулировка для решения собственника',
                dataField: 'text_owner',
                dataType: 'string',
                responsivePriority: 3
            },
            /*       {
                       label: 'Кол-во собраний',
                       dataField: 'count_meeting',
                       allowEdit: false,
                       dataType: 'number',
                       responsivePriority: 3,
                       formatFunction: (value: any) => {
                           console.log('value.dataSource')
                     console.log(value.dataSource)
                       }
                   },*/
            {
                label: 'Критерий принятия решения',
                dataField: 'solution_id',
                dataType: 'string',
                responsivePriority: 4,
                width: 100,
                formatFunction: (value: any) => {
                    switch (value.value) {
                        case 1:
                            value.template = constVoteSolution.VOTE_100
                            break;
                        case 2:
                            value.template = constVoteSolution.VOTE_75
                            break;
                        case 3:
                            value.template = constVoteSolution.VOTE_51
                            break;
                        case 4:
                            value.template = constVoteSolution.VOTE_MANY
                            break;
                    }
                }
            },
            {label: 'Комментарий', dataField: 'comment', dataType: 'string', responsivePriority: 3, width: 100},
            /*    {label: 'Дата создания дома', dataField: 'createDate', allowEdit: false, dataType: 'string', responsivePriority: 3},*/
            {
                label: 'Дата создания',
                dataField: 'create_date',
                allowEdit: false,
                dataType: 'string',
                responsivePriority: 3,
                width: 100,
                formatFunction: (value: any) => {
                    new DatePipe('en-US').transform(value.value, 'dd-MM-yyyy');
                    value.template = new DatePipe('en-US').transform(value.value, 'dd.MM.yyyy');
                }
            },
            /*            {
                            label: '', dataField: 'actionEdit', allowEdit: false, width: 50,
                            formatFunction: function (settings: any) {
                                settings.template =
                                    '<a href="javascript:;" class="nav-link rounded action-icon">' +
                                    '<i class="material-icons">edit</i>' + '</a>';
                            }
                        },*/
            {
                label: '', dataField: 'actionDelete', allowEdit: false, width: 50,
                formatFunction: function (settings: any) {
                    settings.template =
                        '<a href="javascript:;" class="nav-link rounded action-icon">' +
                        '<i class="material-icons">delete_forever</i>' + '</a>';
                }
            },
        ],

    }



    /**
     **  Провайдер данных обозначаем какие поля в нем будут
     */
    dataAdapterVote: DataAdapter = new Smart.DataAdapter({
        dataSource: [],
        dataFields: [
            'id: number',
            'priority: string',
            'text_message: string',
            'sum_vote_yes: number',
            'sum_vote_no: number',
            'sum_vote_unknown: number',
            'solution_id: number',
            'all_area: number',
            'min_vote_area: number',
            'sum_vote_area: number',
            'sum_vote_area_percent: number',
            'kvorum: number',

        ],
    })

    dataSourceVote = this.dataAdapterVote;
    /**
     * Настройки таблицы результаты голосования
     */
    resultVoteDataSettings = {
        /**
         * Св-во нужно для инициализации. Для интерфейса. Нужен для норм работы
         */
        onInit: () => {
        },
        currentUrl: this.router.url,
        behavior: {columnResizeMode: 'growAndShrink'},
        questionService: CurrentMeetingComponent.questionService,

        // Параметры таблицы
        pageIndex: 0,
        pageSize: "10",
        filtering: true,
        paging: true,
        sortMode: 'many',
        filterRow: false,
        tooltip: true,
        selection: false,
        editing: false,
        editMode: 'row',
        keyboardNavigation: true,

        // Колонки в таблице
        columns: [
            {
                label: 'id', dataField: 'id', dataType: 'number', responsivePriority: 3, width: 50
            },
            /*          {
                          label: 'Id', dataField: 'actionId', dataType: 'number', responsivePriority: 3, width: 50,
                          formatFunction: function (settings: any) {
                              settings.template =
                                  '<a href="javascript:;" class="nav-link rounded action-icon">' + settings.data.id + '</a>';
                          }
                      },*/
            {
                label: 'priority', dataField: 'priority', dataType: 'number', responsivePriority: 3, width: 100
            },
            {
                label: 'Формулировка для уведомления',
                dataField: 'text_message',
                dataType: 'string',
                responsivePriority: 3
            },
            {
                label: 'Кол-во голосов',
                dataField: 'all_area',
                dataType: 'number',
                responsivePriority: 3
            },
            {
                label: 'Общее количество проголосовавших',
                dataField: 'sum_vote_area',
                dataType: 'number',
                responsivePriority: 3
            },
            {
                label: 'Общее количество проголосовавших, %',
                dataField: 'sum_vote_area_percent',
                dataType: 'number',
                responsivePriority: 3
            },
            {
                label: 'Минимум для кворума',
                dataField: 'min_vote_area',
                dataType: 'number',
                responsivePriority: 3
            },
            {
                label: 'Кворум для принятия решения',
                dataField: 'kvorum',
                dataType: 'number',
                responsivePriority: 3
            },
            {
                label: 'ЗА',
                dataField: 'sum_vote_yes',
                dataType: 'number',
                responsivePriority: 3
            },
            {
                label: 'ПРОТИВ',
                dataField: 'sum_vote_no',
                dataType: 'number',
                responsivePriority: 3
            },
            {
                label: 'ВОЗДЕРЖАЛСЯ',
                dataField: 'sum_vote_unknown',
                dataType: 'number',
                responsivePriority: 3
            },
            /*       {
                       label: 'Кол-во собраний',
                       dataField: 'count_meeting',
                       allowEdit: false,
                       dataType: 'number',
                       responsivePriority: 3,
                       formatFunction: (value: any) => {
                           console.log('value.dataSource')
                     console.log(value.dataSource)
                       }
                   },*/
            {
                label: 'Критерий принятия решения',
                dataField: 'solution_id',
                dataType: 'number',
                responsivePriority: 4,
                width: 100,
                formatFunction: (value: any) => {
                    switch (value.value) {
                        case 1:
                            value.template = constVoteSolution.VOTE_100
                            break;
                        case 2:
                            value.template = constVoteSolution.VOTE_75
                            break;
                        case 3:
                            value.template = constVoteSolution.VOTE_51
                            break;
                        case 4:
                            value.template = constVoteSolution.VOTE_MANY
                            break;
                    }
                }
            },
            /*            {
                            label: '', dataField: 'actionEdit', allowEdit: false, width: 50,
                            formatFunction: function (settings: any) {
                                settings.template =
                                    '<a href="javascript:;" class="nav-link rounded action-icon">' +
                                    '<i class="material-icons">edit</i>' + '</a>';
                            }
                        },*/

        ],

    }

    /**
     * Вызывается обработчик события и апдейтится поле когда закончили редактирование в колонке
     * @param event
     */
    onItemAfterEdit(event: any) {
        this.mSub = this.questionService.updateQuestionValue(event.detail).subscribe(questions => {
            // Получили обновленные данные и записали в this.dataAdapter.dataSource
            // this.getMyQuestion();
            // Скинули все чекбоксы
            // this.tableWithButtons.clearSelection();
        });
    }

    /**
     * Вызывается обработчик события когда кликнули по иконкам или чекбоксу
     * @param event
     */
    onCellClick(event: { detail: { dataField: string; row: { id: number; }; id: string; }; target: any; }) {
        //Клик по иконке edit
        if (event.detail.dataField === 'actionEdit') {
            console.log('actionEdit')
            console.log(event);
            this.router.navigate([this.router.url + '/current-meeting/' + event.detail.row.id]);
        }
        //Клик по иконке delete
        if (event.detail.dataField === 'actionDelete') {
            const params: any[any] = [];
            params.id = event.detail.row.id;
            params.meeting_id = Number(this.urlService.urlParams.meetingId);
            this.deleteQuestion(params);
            document.querySelector('[row-id="' + event.detail.id + '"]')?.remove();
        }

        //Клик по чекбоксу
        if (this.selectedItems === undefined || this.selectedItems.length == 0) {
            this.selectedItems.push(event.detail.row.id);
        } else {
            // Если еще нет значения в массиве то добавляем
            if (!this.selectedItems.includes(event.detail.row.id)) {
                //console.log('еще нет значения');
                this.selectedItems.push(event.detail.row.id);
            } else {
                //console.log('такое есть значения'); Удаляем из массива selectedItems
                const index = this.selectedItems.indexOf(event.detail.row.id, 0);
                //console.log('index - ' + index);
                if (index > -1) {
                    this.selectedItems.splice(index, 1);
                }
            }
        }
    }

    /**
     * Клик по выбрать все чекбоксы. Записываем данные в this.selectedItems
     * @param event
     */
    onChangeCheckbox(event: { detail: { dataField: string; row: { id: number; }; id: string; }; target: any; type: string }) {
        let allCheckboxSelected = document.querySelectorAll('th.selected');
        let sameCheckboxSelected = document.querySelectorAll('th.indeterminate');

        if (allCheckboxSelected.length > 0 || sameCheckboxSelected.length > 0) {
            this.isDisabledButtonDeleteAll = false;

            let checked = document.querySelectorAll('tr[aria-selected="true"]');
            checked.forEach((item, key) => {

                // @ts-ignore
                if (item.querySelector('[data-field="id"]')) {
                    let val = item.querySelector('[data-field="id"]').innerHTML;
                    // Если еще нет значения в массиве то добавляем
                    if (!this.selectedItems.includes(Number(val))) {
                        //console.log('еще нет значения');
                        this.selectedItems.push(Number(val));
                    }
                }

            })
        } else {
            this.selectedItems = [];
            this.isDisabledButtonDeleteAll = true;
        }
    }

    /**
     * Удалить вопрос
     */
    deleteQuestion(params: any): void {
        this.mSub = this.questionService.deleteQuestion(params)
            .subscribe(questions => {
                // Заполнили данными
                // this.dataAdapter.dataSource = questions.result;
            });
    }

    /**
     * Кнопка Удалить все выбранные чекбоксы. Http запрос на сервер и визуал
     */
    deleteSelectedCheckBox(event: any): void {
        // Навешиваем обработчики событий.
        const cancelHandler = (event: CustomEvent): void => {
            const target: HTMLElement = event.target as HTMLElement,
                modal = this.formWindow.nativeElement;
            target.closest('smart-window') === modal ? modal.close() : modal.close();
            this.agreeDeleteCheckbox = false;
        }
        // Подтверждение
        const agreeHandler = (event: CustomEvent): void => {
            const target: HTMLElement = event.target as HTMLElement,
                modal = this.formWindow.nativeElement;
            target.closest('smart-window') === modal ? modal.close() : modal.close();
            this.agreeDeleteCheckbox = true;
            this.isDisabledButtonDeleteAll = true;
            this.deleteSelectedCheckBox(event)
        }
        // @ts-ignore may be null
        document.getElementById('сancelButton').addEventListener('click', cancelHandler);
        // @ts-ignore may be null
        document.getElementById('agreeHandler').addEventListener('click', agreeHandler);
        // Конец навешиваем обработчики событий.

        let sameCheckboxSelected = document.querySelectorAll('th.indeterminate');
        let allCheckboxSelected = document.querySelectorAll('th.selected');
        const target: HTMLElement = event.target as HTMLElement,
            modal = this.formWindow.nativeElement;
        if (allCheckboxSelected.length > 0 || sameCheckboxSelected.length > 0) {
            target.closest('smart-window') === modal ? modal.open() : modal.open();
            this.isDisabledButtonDeleteAll = false;
        } else {
            this.selectedItems = [];
            this.isDisabledButtonDeleteAll = true;
        }
        if (this.agreeDeleteCheckbox) {
            target.closest('smart-window') === modal ? modal.close() : modal.close();
            const params: any[any] = [];
            params.ids = this.selectedItems;
            params.meeting_id = this.urlService.urlParams.meetingId;
            params.countIds = this.selectedItems.length;
            this.mSub = this.questionService.deleteQuestions(params)
                .subscribe(questions => {
                    // Обновили таблицу
                    this.getQuestionsMeeting();
                    // Скинули все чекбоксы
                    this.tableWithButtons.clearSelection();
                });
            this.selectedItems = [];
            this.agreeDeleteCheckbox = false;
            // this.ngOnInit()
            //Перезагружаем текущую страницу
            location.reload()
        }
        // this.router.navigate(['houses/']);

        this.agreeDeleteCheckbox = false;
    }

    /**
     * Создать уведомление о собрании собственников
     */
    createNotifyMeetingDocument(): void {
        const params: any[any] = [];
        params.meetingId = this.urlService.urlParams.meetingId;
        params.houseId = this.urlService.urlParams.houseId;
        params.userId = this.storageService.getUser();
        this.mSub = this.meetingService.createNotifyMeetingDoc(params).subscribe(doc => {
            this.downLoadFile(doc, "stream", 'Уведомление_о_собрании.docx')
        });
    }

    /**
     * Создать акт о размещении уведомления о собрании собственников
     */
    createActNotifyDocument(): void {
        const params: any[any] = [];
        params.meetingId = this.urlService.urlParams.meetingId;
        params.houseId = this.urlService.urlParams.houseId;
        params.userId = this.storageService.getUser();
        this.mSub = this.meetingService.createActNotifyDoc(params).subscribe(doc =>
            this.downLoadFile(doc, "stream", 'Акт_уведомление_о_собрании.docx')
        );
    }

    /**
     * Создать реестр собственников
     */
    createOwnersDocument(): void {
        const params: any[any] = [];
        params.meetingId = this.urlService.urlParams.meetingId;
        params.houseId = this.urlService.urlParams.houseId;
        params.userId = this.storageService.getUser();
        this.mSub = this.meetingService.createOwnersDoc(params).subscribe(doc =>
            this.downLoadFile(doc, "stream", 'Реестр собственников.docx')
        );
    }

  /**
   * Создать реестр собственников принявших участие в голосовании
   */
  createOwnersDocumentFact(): void {
    const params: any[any] = [];
    params.meetingId = this.urlService.urlParams.meetingId;
    params.houseId = this.urlService.urlParams.houseId;
    params.userId = this.storageService.getUser();
    this.mSub = this.meetingService.createOwnersDocFact(params).subscribe(doc =>
      this.downLoadFile(doc, "stream", 'Реестр собственников принявших участие в голосовании.docx')
    );
  }


    /**
     * Создать бюллетень для голосования собственников
     */
    createOwnersAnketa(): void {
        const params: any[any] = [];
        params.meetingId = this.urlService.urlParams.meetingId;
        params.houseId = this.urlService.urlParams.houseId;
        params.userId = this.storageService.getUser();
        this.mSub = this.meetingService.createAnketaOwnersDoc(params).subscribe(doc =>
            this.downLoadFile(doc, "stream", 'Бюллетень для голосования собственников.docx')
        );
    }

    /**
     * Создать протокол голосования собственников
     */
    createOwnersResults(): void {
        const params: any[any] = [];
        params.meetingId = this.urlService.urlParams.meetingId;
        params.houseId = this.urlService.urlParams.houseId;
        params.userId = this.storageService.getUser();
        this.mSub = this.meetingService.createOwnersResults(params).subscribe(doc =>
            this.downLoadFile(doc, "stream", 'Протокол голосования собственников.docx')
        );
    }

    /**
     * Скачать файл на компьютер
     * @param data - ответ от сервера, данные.
     * @param fileType - тип возвращаемых данных.
     * @param docName - название документа.
     */
    downLoadFile(data: any, fileType: string, docName: string) {
        let type = null;

        switch (fileType) {
            case 'pdf':
                type = { type: "application/pdf" }
                break;
            case 'csv':
                type = { type: 'text/csv' }
                break;
            case 'xls':
                type = { type: 'application/vnd.ms-excel' }
                break;
            case 'stream':
                type = { type: 'application/octet-stream' }
                break;

            default:
                break;
        }

        let blob = new Blob([data], type);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = docName;
        link.click();
    }


    /**
     * Скопировать вопросы из другого собрания
     */
    copyAnotherMeeting(): void {
        this.router.navigate([this.router.url + '/copy-question']);
    }

    /**
     * Получить вопросы из шаблонов
     */
    getStandartQuestion(): void {
        this.router.navigate([this.router.url + '/standart-question']);
    }

    /**
     * Получить мои вопросы
     */
    getMyQuestion(): void {
        this.router.navigate([this.router.url + '/my-question']);
    }

    /**
     * Создать новый вопрос относится только к текущему собранию
     */
    addQuestion(): void {
        this.router.navigate([this.router.url + '/create-question']);
        // this.router.navigate([this.router.url.replace('my-question','create-question')]);
    }

    /**
     * Создаем уведомление для текущего собрания
     */
    createNotification() {
        if (this.notificationOwnerForm.valid) {
            // Отправляем параметры на back + в массив post
            const params = {...this.notificationOwnerForm.value}
            this.meetingService.apartmentOwner.forEach((item: any) => {
                if (item.name === this.apartmentInitiatorId) {
                    this.apartmentOwnerId = item.id
                }
            })

            //Если данных нет в бд
            if (this.preloadMeetingNotify.id == 0) {
                params.meetingQueueId = parseInt(this.meetingQueueId);
                params.meetingPeopleId = parseInt(this.meetingPeopleId);
                params.meetingOnlineId = parseInt(this.meetingOnlineId);
                params.meetingInitiatorId = parseInt(this.meetingInitiatorId);
                params.meetingBeginOnline = new Date().toISOString();
                params.meetingDateBegin = new Date().toISOString();
                params.meetingDateEnd = new Date().toISOString();
                params.meetingDateNotify = new Date().toISOString();
                params.meetingEndSolution = new Date().toISOString();
                params.apartmentOwnerId = parseInt(this.apartmentOwnerId);
            } else {
                params.meetingQueueId = this.preloadMeetingNotify['meeting_queue_id'];
                params.meetingPeopleId = this.preloadMeetingNotify['meeting_people_id'];
                params.meetingOnlineId = this.preloadMeetingNotify['meeting_online_id'];
                params.meetingInitiatorId = this.preloadMeetingNotify['meeting_initiator_id'];
                params.apartmentOwnerId = parseInt(this.apartmentOwnerId);
            }

            params.meetingId = Number(this.urlService.urlParams.meetingId);
            this.mSub = this.meetingService.createNotify(params).subscribe(_ => {
            });
            // Очистка формы после submit
            //this.notificationOwnerForm.reset();
            // location.reload()
            // this.router.navigate([this.router.url]);
        } else {
            console.log(this.notificationOwnerForm.value);
            console.log(this.notificationOwnerForm.errors);
        }
    }

    changeMeetingInitiatorId(event: any) {
        this.meetingInitiatorId = event.detail.value;
        console.log(this.meetingInitiatorId);
        (this.meetingInitiatorId == 2) ? this.showInputNameInitiator = true : this.showInputNameInitiator = false;
        if (this.meetingService.apartmentOwner) {
            console.log(this.meetingService.apartmentOwner);
            this.meetingService.apartmentOwner.forEach((item: { name: any; id: any }) => {
                this.meetingService.apartmentOwnerSource.push(item.name)
            })
        }
        console.log(this.meetingService.apartmentOwnerSource[0]);
    }

    changeApartmentInitiatorId(event: any) {
        this.apartmentInitiatorId = event.detail.value;
        console.log(this.apartmentInitiatorId);
    }

    changeMeetingOnlineId(event: any) {
        this.meetingOnlineId = event.detail.value
    }

    changeMeetingPeopleId(event: any) {
        this.meetingPeopleId = event.detail.value
    }

    changeMeetingQueueId(event: any) {
        this.meetingQueueId = event.detail.value
    }

    changeMeetingEndSolution(event: any) {
        /*  var date = new Date(event.detail.value.dateData).toUTCString();
          console.log(date)
          this.meetingEndSolution = event.detail.value*/
    }

}
