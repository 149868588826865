import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HttpErrorHandler, HandleError} from '../../../services/http-error-handler.service';

import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Meeting} from '../../../models/metting';
import {StorageService} from "../../../services/storage.service";


// Благодаря такому декоратору @Injectable мы можем инжектировать в данный сервис другие сущности
@Injectable({
    // Данной строкой мы говорим чтобы ангуляр зарегал данный сервис в корневом модуле (в системе в единственном экз.).
    providedIn: 'root'
    // Поэтому из корневого модуля можно исключить houseService так как он регается тут
    // Данный сервис зареган глобально и виден во всех компонентах
    // Можно регать сервисы локально (каждый раз создается копия сервиса с новыми данными) в конкретном компоненте тогда нужно в конкретный компонент в декоратор
    //  @Component({
    //    providers: [MessageService],
    //  })
    // Также можно использовать сервисы внутри других сервисов. Внедрение через конструктор
})

export class HouseService {

    private readonly handleError: HandleError;
    housesUrl = '/api/v1/house/';  // URL to web api

    constructor(
        private http: HttpClient,
        private storageService: StorageService,
        httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('HouseService');
    }

    getTheme() {
        return document.body.getAttribute('theme');
    }

    getHouses(): Observable<any> {
        return this.http.post(this.housesUrl + 'getHouses', {
            params: {
                user_id: this.storageService.getUser(),
            }
        })
            .pipe(
                catchError(this.handleError('getHouses', []))
            );
    }

    getCountMeetings(): Observable<any> {
        return this.http.post(this.housesUrl + 'getCountMeetings', [])
            .pipe(
                catchError(this.handleError('getCountMeetings', []))
            );
    }

    updateOwnerValue(detail: any): Observable<{}> {
        const url = `${this.housesUrl + 'updateOwnerValue'}`;
        return this.http.post(url, {
            params: detail
        })
            .pipe(
                catchError(this.handleError('updateOwnerValue'))
            );
    }

    updateHouseValue(detail: any): Observable<{}> {
        const url = `${this.housesUrl + 'updateHouseValue'}`;
        return this.http.post(url, {
            params: detail
        })
            .pipe(
                catchError(this.handleError('updateHouseValue'))
            );
    }

    searchHouses(term: string): Observable<any> {
        term = term.trim();

        // Add safe, URL encoded search parameter if there is a search term
        const options = term ?
            {params: new HttpParams().set('name', term)} : {};

        return this.http.post(this.housesUrl, options)
            .pipe(
                catchError(this.handleError('searchHouses', []))
            );
    }

    deleteHouse(id: number): Observable<{}> {
        const url = `${this.housesUrl + 'deleteHouse'}/${id}`;
        return this.http.post(url, {
            params: {
                "id": id,
            }
        })
            .pipe(
                catchError(this.handleError('deleteHouse'))
            );
    }

    deleteOwner(data: any): Observable<{}> {
        const url = `${this.housesUrl + 'deleteOwner'}/${data.id}`;
        return this.http.post(url, {
            params: {
                "id": data.id,
                "house_id": Number(data.house_id),
            }
        })
            .pipe(
                catchError(this.handleError('deleteOwner'))
            );
    }

    deleteAllOwners(data: any): Observable<{}> {
        const url = `${this.housesUrl + 'deleteAllOwners'}`;
        return this.http.post(url, {
            params: {
                "house_id": Number(data.house_id),
            }
        })
            .pipe(
                catchError(this.handleError('deleteOwner'))
            );
    }

    deleteHouses(ids: any): Observable<{}> {
        const url = `${this.housesUrl + 'deleteHouses'}`;
        return this.http.post(url, {
            params: {
                "ids": ids,
            }
        })
            .pipe(
                catchError(this.handleError('deleteHouses'))
            );
    }

    deleteOwners(detail: any): Observable<{}> {
        const url = `${this.housesUrl + 'deleteOwners'}`;
        return this.http.post(url, {
            params: {
                "ids": detail.owner_ids,
                "house_id": Number(detail.house_id),
            }
        })
            .pipe(
                catchError(this.handleError('deleteOwners'))
            );
    }

    updateHouse(meeting: Meeting): Observable<any> {
        return this.http.put(this.housesUrl, meeting)
            .pipe(
                catchError(this.handleError('updateHouse', meeting))
            );
    }

    getOwners(detail: any): Observable<any> {
        const url = `${this.housesUrl + 'getOwners'}`;

        return this.http.post(url, {
            params: {
                house_id: Number(detail.houseId)
            }
        })
            .pipe(
                catchError(this.handleError('getOwners', []))
            );
    }

    createHouse(params: any): Observable<any> {
        const url = `${this.housesUrl + 'createHouse'}`;
        return this.http.post(url, {
            params: params
        })
            .pipe(
                catchError(this.handleError('createHouse'))
            );
    }

    createOwner(params: any): Observable<any> {
        const url = `${this.housesUrl + 'createOwner'}`;
        return this.http.post(url, {
            params: params
        })
            .pipe(
                catchError(this.handleError('createOwner'))
            );
    }
}
