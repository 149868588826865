export const exportTypeConst = {
    TYPE_YML: 'yml',
    TYPE_CSV: 'csv',
    TYPE_XLS: 'xls'
};

export const ERROR_CODE = {

};

export const constLocalStorage = {
    SELECTED_STANDART_QUESTIONS_IDS : 'selectedStandartQuestionsIds',
    SELECTED_MY_QUESTIONS_IDS : 'selectedMyQuestionsIds',
    ROW_MY_QUESTIONS_IDS : 'rowMyQuestionsIds',
    ROW_VOTE_IDS : 'rowVoteIds',
    ROW_STANDART_QUESTIONS_IDS : 'rowStandartQuestionsIds'
};

export const constVote = {
    VOTE_YES : 1,
    VOTE_NO : 2,
    VOTE_UNKNOWN : 3,
};