import {Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ViewEncapsulation, Input} from '@angular/core';
import {MeetingService} from "src/app/pages/meetings/services/meeting.service";
import {Subscription} from "rxjs";
import {BrowserModule} from "@angular/platform-browser";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {DateTimePickerComponent} from "smart-webcomponents-angular/datetimepicker";
import {HouseService} from "../../services/house.service";
import {FileUploadService} from "../../services/file-upload.service";
import {HttpEventType, HttpResponse} from "@angular/common/http";
import {UrlService} from "../../services/url.service";
import {WindowComponent} from "smart-webcomponents-angular/window";
import {ButtonComponent} from "smart-webcomponents-angular/button";
import {TableComponent} from "smart-webcomponents-angular/table";
import {DataAdapter, Smart} from "smart-webcomponents-angular/grid";
import { CommonModule } from '@angular/common'; // импортнули изза ошибки Can’t bind to ‘ngif’ since it isn’t a known property of ‘div’
// этот модуль нужен, хотя и не подсвечивается


// @ts-ignore
@Component({
    selector: 'form-update-house',
    templateUrl: 'form-update-house.component.html',
    providers: [BrowserModule,CommonModule],
    styleUrls: ['form-update-house.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class FormUpdateHouseComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild('datetimepickerBegin', {
        read: DateTimePickerComponent,
        static: false
    })

    datetimepickerBegin!: DateTimePickerComponent;

    @ViewChild('datetimepickerEnd', {
        read: DateTimePickerComponent,
        static: false
    })

    datetimepickerEnd!: DateTimePickerComponent;

    // Переменная отвечающая за подписку
    subscription1$: Subscription;
    subscription2$: Subscription;
    subscription3$: Subscription;
    subscriptions: Subscription[] = [];
    mSubRouter: Subscription;
    // Переменная отвечающая за подписку
    mSub: Subscription

    isLoaded:boolean=false;

    isUploadingOwner = false; // Загружены ли пользователи

    selectedFiles?: FileList;
    currentFile?: File;
    progress = 0;
    message = '';

    // Переменные отвечающие за чекбоксы
    selectedItems: Array<any> = [];
    agreeDeleteCheckbox: boolean = false; // Флаг кнопка согласие в модальном окне
    isDisabledButtonDeleteAll: boolean = true; // Изначально отключено. Кнопка удалить выбранные

    agreeDeleteAllOwners: boolean = false; // Флаг кнопка согласие в модальном окне
    isDisabledButtonAgreeAllOwners: boolean = false; // Изначально включено. Кнопка удалить всех собственников

    selectFile(event: any): void {
        this.selectedFiles = event.target.files;
    }

    //Флаг, который указывает весь html данного компонента отображать
    isCurrentMeeting:boolean = false

    constructor(
        private meetingService: MeetingService,
        private houseService: HouseService,
        private router: Router,
        private urlService: UrlService,
        private activeRouter: ActivatedRoute, // Отображает текущий роут компонента
        private uploadService: FileUploadService
    ) {
        Object.assign(FormUpdateHouseComponent, {
            meetingService,
            uploadService,
        });
    }

    @ViewChild('button', {read: ButtonComponent, static: false}) button!: ButtonComponent;
    @ViewChild('modalWindow', {read: WindowComponent, static: false}) modalWindow: WindowComponent;
    @ViewChild('formWindow', {read: WindowComponent, static: false}) formWindow: WindowComponent;
    @ViewChild('formWindowDeleteAll', {read: WindowComponent, static: false}) formWindowDeleteAll: WindowComponent;
    @ViewChild('table', {read: TableComponent, static: false}) tableWithButtons!: TableComponent;


    /**
     * Запускается 1 на странице перед выводом визуала
     */
    ngOnInit() {
        console.log('Запустился ngOnInit()');
        // Получаем параметры из url
        this.activeRouter.params.subscribe((params: Params) => {
            this.urlService.urlParams = params
        })
        if (this.router.url.includes('current-meeting')) {
            this.isCurrentMeeting = true
        }
        // Получаем начальные данные по запросу
        this.getOwners();
    }


    /**
     * Очищаем подписку на стрим чтобы не было утечек по памяти
     */
    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());

        if (this.mSubRouter) {
            this.mSubRouter.unsubscribe();
        }
    }

    /**
     * Запускается 3 - им
     */
    init(): void {
        console.log(this.isLoaded)
/*        this.fileupload.messages = {
            'en': {
                'browse': 'Загрузить собственников (Excel)',
                'uploadFile': 'Upload File',
                'cancelFile': 'Cancel File',
                'pauseFile': 'Pause File',
                'uploadAll': 'Upload All',
                'cancelAll': 'Cancel All',
                'pauseAll': 'Pause All',
                'totalFiles': 'Total files: ',
                'connectionError': '{{elementType}}: File Upload requires connection to the server.',
                'wrongItemIndex': '{{elementType}}: There is no file with such an index in the list of uploaded files.',
                'tooLongFileName': '{{elementType}}: File name is too long.'
            },
        };
        this.fileupload.locale = 'en';*/

        // Можно навесить на addEventListener либо в шаблоне html как сделано у нас
       // this.fileupload.addEventListener('uploadStarted', this.onUploadStarted);
    }

    /**
     * Св-во нужно для инициализации. Для интерфейса. Нужен для норм работы
     */
    onInit(): void {
        console.log('Запустился onInit()');
    };

    /**
     * Запускается 2 на странице после ngOnInit().
     */
    ngAfterViewInit() {
        console.log('Loading ngAfterViewInit form-update-meeting');
        // грузим метод чтобы загрузить that.fileupload.messages переводы названия кнопок
        this.init();
    }

    upload(event: any): any  {
        this.progress = 0;
        if (this.selectedFiles) {
            const file: File | null = this.selectedFiles.item(0);
            const params: any[any] = [];
            params['file'] = this.selectedFiles.item(0);
            params['house_id'] = this.urlService.urlParams.houseId;
            console.log(params);
            if (file) {
                this.currentFile = file;

                this.uploadService.upload(params).subscribe({
                    next: (event: any) => {
                        if (event.type === HttpEventType.UploadProgress) {
                            this.progress = Math.round(100 * event.loaded / event.total);
                            this.isUploadingOwner = true;
                        } else if (event instanceof HttpResponse) {
                            this.message = event.body.message;
                        }
                    },
                    error: (err: any) => {
                        console.log(err);
                        this.progress = 0;

                        if (err.error && err.error.message) {
                            this.message = err.error.message;
                        } else {
                            this.message = 'Could not upload the file!';
                        }

                        this.currentFile = undefined;
                    }
                });
            }

            this.selectedFiles = undefined;
        }
    }

    onUploadCompleted(event: any): any {

    }
    onUploadError(event: any): any {

    }

    onWindowReady(event: any): void {
        this.init();
    }

    onButtonClick(event: any): void {
        console.log('sdsds');
        this.formWindow.opened ? this.formWindow.close() : this.formWindow.open();
    }

    onButtonClick2(event: any): void {
        this.formWindow.close();
    }

    /**
     * Получить всех владельцев квартир в доме
     */
    getOwners(): void {
        // Параметр houseId берется из роутинга из url. В роутинге есть параметр 'houses/:houseId'
        this.mSub = this.houseService.getOwners(this.urlService.urlParams)
            .subscribe(result => {
                // Заполнили данными
                this.dataAdapter.dataSource = result.results.owners;
                this.meetingService.apartmentOwner = result.results.owners;
            });
    }

    /**
     **  Провайдер данных обозначаем какие поля в нем будут
     */
    dataAdapter: DataAdapter = new Smart.DataAdapter({
        dataSource: [],
        dataFields: [
            'id: number',
            'name: string',
            'email: string',
            'phone: string',
            'registration_number: string',
            'registration_date: string',
            'room_number: string',
            'cadastral_number: string',
            'area: number',
            'portion: number',
            'portition_area: number',
            'typeId: number',
        ],
    })

    dataSource = this.dataAdapter;

    /**
     * Настройки таблицы
     */
    ownersDataSettings = {
        /**
         * Св-во нужно для инициализации. Для интерфейса. Нужен для норм работы
         */

        behavior: {columnResizeMode: 'growAndShrink'},

        // Параметры таблицы
        pageIndex: 0,
        pageSize: "10",
        filtering: true,
        paging: true,
        sortMode: 'many',
        filterRow: false,
        tooltip: true,
        selection: true,
        editing: true,
        editMode: 'row',
        keyboardNavigation: true,

        // Колонки в таблице
        columns: [
            {
                label: 'id', dataField: 'id', dataType: 'number', responsivePriority: 3, width: 50
            },
            {label: 'ФИО', dataField: 'name', dataType: 'string', allowEdit: true, responsivePriority: 3, width: 150},
            /*           {
                           label: 'Дата собрания', dataField: 'meetingDateBegin', allowEdit: false, dataType: 'string', responsivePriority: 3,
                           formatFunction: (value:any) => {
                               new DatePipe('en-US').transform(value.value, 'dd-MM-yyyy');
                               value.template = new DatePipe('en-US').transform(value.value, 'dd.MM.yyyy');
                           }
                       },*/
            /*  {label: 'Адрес', dataField: 'address', dataType: 'string', responsivePriority: 4},*/
            {label: 'Email', dataField: 'email', dataType: 'string', allowEdit: true, width: 150},
            {label: 'Телефон', dataField: 'phone', dataType: 'string', allowEdit: true, width: 150},
            {label: 'Номер регистрации', dataField: 'registration_number', dataType: 'string', responsivePriority: 30},
            {label: 'Дата регистрации', dataField: 'registration_date', dataType: 'string', responsivePriority: 30},
            {label: 'Номер помещения', dataField: 'room_number', dataType: 'string', responsivePriority: 30},
            {label: 'Кадастровый номер', dataField: 'cadastral_number', dataType: 'string', responsivePriority: 30},
            {label: 'Площадь', dataField: 'area', dataType: 'number', responsivePriority: 3,width: 50},
            {label: 'Доля', dataField: 'portion', dataType: 'number', responsivePriority: 3,width: 50},
            {label: 'Площадь доли', dataField: 'portition_area', dataType: 'number', responsivePriority: 50},
            {label: 'Член ТСЖ', dataField: 'typeId', dataType: 'number', responsivePriority: 50},
            {
                label: '', dataField: 'actionEdit', allowEdit: false, width: 50,
                formatFunction: function (settings: any) {
                    settings.template =
                        '<a href="javascript:;" class="nav-link rounded action-icon">' +
                        '<i class="material-icons">edit</i>' + '</a>';
                }
            },
            {
                label: '', dataField: 'actionDelete', allowEdit: false, width: 50,
                formatFunction: function (settings: any) {
                    settings.template =
                        '<a href="javascript:;" class="nav-link rounded action-icon">' +
                        '<i class="material-icons">delete_forever</i>' + '</a>';
                }
            },
        ],
    }

    /**
     * Вызывается обработчик события и апдейтится поле когда закончили редактирование в колонке
     * @param event
     */
    onItemAfterEdit(event: any) {
        this.mSub = this.houseService.updateOwnerValue(event.detail).subscribe(owner => {
            // Получили обновленные данные и записали в this.dataAdapter.dataSource
            this.getOwners();
            // Скинули все чекбоксы
            this.tableWithButtons.clearSelection();
        });
    }

    /**
     * Вызывается обработчик события когда кликнули по иконкам или чекбоксу
     * @param event
     */
    onCellClick(event: { detail: { dataField: string; row: { id: number; }; id: string; }; target: any; }) {
        //Клик по иконке edit
        if (event.detail.dataField === 'actionEdit') {
            this.urlService.urlParams = event.detail.row.id;
            this.router.navigate(['houses/' + event.detail.row.id]);
        }
        //Клик по иконке delete
        if (event.detail.dataField === 'actionDelete') {
            this.deleteOwner(event.detail.row.id)
            document.querySelector('[row-id="' + event.detail.id + '"]')?.remove();
        }

        //Клик по чекбоксу
        if (this.selectedItems === undefined || this.selectedItems.length == 0) {
            this.selectedItems.push(event.detail.row.id);
        } else {
            // Если еще нет значения в массиве то добавляем
            if (!this.selectedItems.includes(event.detail.row.id)) {
                //console.log('еще нет значения');
                this.selectedItems.push(event.detail.row.id);
            } else {
                //console.log('такое есть значения'); Удаляем из массива selectedItems
                const index = this.selectedItems.indexOf(event.detail.row.id, 0);
                //console.log('index - ' + index);
                if (index > -1) {
                    this.selectedItems.splice(index, 1);
                }
            }
        }
    }

    /**
     * Клик по выбрать все чекбоксы. Записываем данные в this.selectedItems
     * @param event
     */
    onChangeCheckbox(event: { detail: { dataField: string; row: { id: number; }; id: string; }; target: any; type: string }) {
        let allCheckboxSelected = document.querySelectorAll('th.selected');
        let sameCheckboxSelected = document.querySelectorAll('th.indeterminate');

        if (allCheckboxSelected.length > 0 || sameCheckboxSelected.length > 0) {
            this.isDisabledButtonDeleteAll = false;

            let checked = document.querySelectorAll('tr[aria-selected="true"]');
            checked.forEach((item, key) => {

                let val = item.querySelector('[data-field="id"]').innerHTML;
                // Если еще нет значения в массиве то добавляем
                if (!this.selectedItems.includes(Number(val))) {
                    //console.log('еще нет значения');
                    this.selectedItems.push(Number(val));
                }
            })
        } else {
            this.selectedItems = [];
            this.isDisabledButtonDeleteAll = true;
        }
    }

    /**
     * Удалить всех собственников
     */
    deleteAllOwners(event: any): void {
        // Открываем модальное окно
        const target: HTMLElement = event.target as HTMLElement,
            modal = this.formWindowDeleteAll.nativeElement;
        target.closest('#formWindowDeleteAll') === modal ? modal.open() : modal.open();

        // Навешиваем обработчики событий.
        const cancelButtonformWindowDeleteAll = (event: any): void => {
            const target: HTMLElement = event.target as HTMLElement,
                modal = this.formWindowDeleteAll.nativeElement;
            target.closest('#formWindowDeleteAll') === modal ? modal.close() : modal.close();

            this.agreeDeleteAllOwners = false;
        }
        // Подтверждение
        const agreeHandlerformWindowDeleteAll = (event: any): void => {
            const target: HTMLElement = event.target as HTMLElement,
                modal = this.formWindowDeleteAll.nativeElement;
            target.closest('#formWindowDeleteAll') === modal ? modal.close() : modal.close();
            this.agreeDeleteAllOwners = true;
            this.isDisabledButtonAgreeAllOwners = true;

            // Если есть флаг и клик/событие (а то этот код не успевал выполнятся)
            if (this.agreeDeleteAllOwners && event) {
                const params: any[any] = [];
                params['house_id'] = this.urlService.urlParams.houseId;
                // Закрываем модальное окно
                target.closest('#formWindowDeleteAll') === modal ? modal.close() : modal.close();
                this.mSub = this.houseService.deleteAllOwners(params)
                    .subscribe(houses => {
                        // Скинули все чекбоксы
                        this.tableWithButtons.clearSelection();
                    });
                this.agreeDeleteAllOwners = false;
                this.ngOnInit()
                //Перезагружаем текущую страницу
               // location.reload()
            }

        }
        document.getElementById('сancelButtonformWindowDeleteAll').addEventListener('click', cancelButtonformWindowDeleteAll);
        document.getElementById('agreeHandlerformWindowDeleteAll').addEventListener('click', agreeHandlerformWindowDeleteAll);
        // Конец навешиваем обработчики событий.

        // this.router.navigate(['houses/']);

        this.agreeDeleteAllOwners = false;
    }

    /**
     * Создать нового владельца
     */
    addOwners(event: any): void {
        this.router.navigate(['houses/' + this.urlService.urlParams.houseId + '/create-owner']).then();
    }

    /**
     * Удалить владельца
     */
    deleteOwner(id: number): void {
        const params: any[any] = [];
        params['id'] = id;
        params['house_id'] = this.urlService.urlParams.houseId;
        this.mSub = this.houseService.deleteOwner(params)
            .subscribe(houses => {
                // Заполнили данными
                // this.dataAdapter.dataSource = meetings.result;
            });
    }

    /**
     * Кнопка Удалить все выбранные чекбоксы. Http запрос на сервер и визуал
     */
    deleteSelectedCheckBox(event: any): void {
        // Навешиваем обработчики событий.
        const cancelHandler = (event: any): void => {
            const target: HTMLElement = event.target as HTMLElement,
                modal = this.formWindow.nativeElement;
            target.closest('#formWindow') === modal ? modal.close() : modal.close();

            this.agreeDeleteCheckbox = false;
        }
        // Подтверждение
        const agreeHandler = (event: any): void => {
            const target: HTMLElement = event.target as HTMLElement,
                modal = this.formWindow.nativeElement;
            target.closest('#formWindow') === modal ? modal.close() : modal.close();
            this.agreeDeleteCheckbox = true;
            this.isDisabledButtonDeleteAll = true;
            this.deleteSelectedCheckBox(event)
        }

        document.getElementById('сancelButton').addEventListener('click', cancelHandler);

        document.getElementById('agreeHandler').addEventListener('click', agreeHandler);
        // Конец навешиваем обработчики событий.

        let sameCheckboxSelected = document.querySelectorAll('th.indeterminate');
        let allCheckboxSelected = document.querySelectorAll('th.selected');
        const target: HTMLElement = event.target as HTMLElement,
            modal = this.formWindow.nativeElement;
        if (allCheckboxSelected.length > 0 || sameCheckboxSelected.length > 0) {
            target.closest('#formWindow') === modal ? modal.open() : modal.open();
            this.isDisabledButtonDeleteAll = false;
        } else {
            this.selectedItems = [];
            this.isDisabledButtonDeleteAll = true;
        }

        if (this.agreeDeleteCheckbox) {
            const params: any[any] = [];
            params['owner_ids'] =  this.selectedItems;
            params['house_id'] = this.urlService.urlParams.houseId;

            target.closest('#formWindow') === modal ? modal.close() : modal.close();
            this.mSub = this.houseService.deleteOwners(params)
                .subscribe(houses => {
                    // Обновили таблицу
                    this.getOwners();
                    // Скинули все чекбоксы
                    this.tableWithButtons.clearSelection();
                });
            this.selectedItems = [];
            this.agreeDeleteCheckbox = false;
            // this.ngit()
            //Перезагружаем текущую страницу
            location.reload()
        }
        // this.router.navigate(['houses/']);

        this.agreeDeleteCheckbox = false;
    }

}
