export const exportTypeConst = {
    TYPE_YML: 'yml',
    TYPE_CSV: 'csv',
    TYPE_XLS: 'xls'
};

export const ERROR_CODE = {

};


/**
 * Константы для хранения Ids вопросов в LocalStorage
 */
export const constLocalStorage = {
    SELECTED_STANDART_QUESTIONS_IDS : 'selectedStandartQuestionsIds',
    SELECTED_MY_QUESTIONS_IDS : 'selectedMyQuestionsIds',
    ROW_MY_QUESTIONS_IDS : 'rowMyQuestionsIds',
    ROW_VOTE_IDS : 'rowVoteIds',
    ROW_STANDART_QUESTIONS_IDS : 'rowStandartQuestionsIds'
};


/**
 * Константы для голосования
 */
export const constVote = {
    VOTE_YES : 1,
    VOTE_NO : 2,
    VOTE_UNKNOWN : 3,
};

/**
 * Константы для критерия принятия решения
 */
export const constVoteSolution = {
    VOTE_100 : "100%",
    VOTE_75 : "2/3 от общего числа собственников",
    VOTE_51 : "Более 50% от общего числа голосов собственников",
    VOTE_MANY : "Большинство голосов от принявших участия в собрании",
};