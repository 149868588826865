import {Component, OnInit, OnDestroy, AfterViewInit, ViewEncapsulation} from '@angular/core';
import {MeetingService} from "src/app/pages/meetings/services/meeting.service";
import {WindowModule} from 'smart-webcomponents-angular/window';
import {ButtonModule} from "smart-webcomponents-angular/button";
import {BrowserModule} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import { CommonModule } from '@angular/common'; // импортнули изза ошибки Can’t bind to ‘ngif’ since it isn’t a known property of ‘div’

@Component({
    selector: 'create-question',
    templateUrl: 'create-question.component.html',
    providers: [BrowserModule, ButtonModule, WindowModule, CommonModule],
    styleUrls: ['create-question.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CreateQuestionComponent implements AfterViewInit, OnDestroy, OnInit {


    constructor(
        public meetingService: MeetingService,
        private router: Router,
        private activeRouter: ActivatedRoute // Отображает текущий роут компонента
    ) {
        Object.assign(CreateQuestionComponent, {
            MeetingService,
        });
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
    }

    // Метод вызывается когда инициализируется компонент
    ngOnInit(): void {
console.log('jjjjjj')
    }

}
