import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    AfterViewInit,
    ViewEncapsulation,
    NgModule
} from '@angular/core';
import {MeetingService} from "src/app/pages/meetings/services/meeting.service";
import {Subscription} from "rxjs";
import {BrowserModule} from "@angular/platform-browser"; // Can't bind to 'ngIf' since it isn't
import {ActivatedRoute, Params, Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DateTimePickerComponent} from "smart-webcomponents-angular/datetimepicker";
import {HouseService} from "../services/house.service";
import {UrlService} from "../services/url.service";
import {StorageService} from "../../../services/storage.service";
import {CommonModule} from '@angular/common'; // импортнули изза ошибки Can’t bind to ‘ngif’ since it isn’t a known property of ‘div’
// этот модуль нужен, хотя и не подсвечивается

// @ts-ignore
@Component({
    selector: 'form-create-meeting',
    templateUrl: 'form-create-meeting.component.html',
    providers: [BrowserModule, CommonModule],
    styleUrls: ['form-create-meeting.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class FormCreateMeetingComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild('datetimepickerBegin', {
        read: DateTimePickerComponent,
        static: false
    }) datetimepickerBegin!: DateTimePickerComponent;
    @ViewChild('datetimepickerEnd', {
        read: DateTimePickerComponent,
        static: false
    }) datetimepickerEnd!: DateTimePickerComponent;

    // Переменная отвечающая за подписку
    subscription1$: Subscription;
    subscription2$: Subscription;
    subscription3$: Subscription;
    subscriptions: Subscription[] = [];

    mSubRouter: Subscription

    meetingCreateForm: FormGroup

    // Переменная содержит выбранное значение из выпадающего списка
    meetingTypeId: any
    meetingParticipantsId: any
    // Флаг показа дополнительных input
    showInputNameTsg: boolean = false
    showInputNameTsn: boolean = false

    isLoaded:boolean=false;

    constructor(
        public meetingService: MeetingService,
        public houseService: HouseService,
        public urlService: UrlService,
        private router: Router,
        private activeRouter: ActivatedRoute, // Отображает текущий роут компонента
        private storageService: StorageService,
    ) {
        Object.assign(FormCreateMeetingComponent, {
            MeetingService,
        });
    }

    /**
     * Запускается 1 на странице перед выводом визуала
     */
    ngOnInit() {
        console.log('Запустился ngOnInit()');
        // Получаем данные через предзагрузку используем resolver meetingTypePreload и meetingParticipantsPreload
        // meetingParticipantsPreload - имя ресолвера используется в компоненте app-routing.module.ts
        this.subscription1$ = this.activeRouter.data.subscribe(
            (response: any) => {
                this.meetingService.meetingType = response.meetingTypePreload.results;
            }
        )
        this.subscription2$ = this.activeRouter.data.subscribe(
            (response: any) => {
                this.meetingService.meetingParticipants = response.meetingParticipantsPreload.results;
            }
        )
        // Добавляем все подписки в массив чтобы потом от них отписаться
        this.subscriptions.push(this.subscription1$);
        this.subscriptions.push(this.subscription2$);

        // Инициализируем форму и поля
        this.meetingCreateForm = new FormGroup<any>({
            meeting_number: new FormControl('', [Validators.required, Validators.minLength(1)]),
            meetingParticipants: new FormControl('', [Validators.required]),
            meetingType: new FormControl('', [Validators.required]),
            meetingComment: new FormControl('', []),
            meetingDateBegin: new FormControl('', []),
            meetingDateEnd: new FormControl('', []),
            meetingTsgName: new FormControl('', [Validators.minLength(3)]),
            meetingTsnName: new FormControl('', [Validators.minLength(3)])
        })

    }

    changeMeetingType(event: any) {
        this.meetingTypeId = event.detail.value;
        (this.meetingTypeId == 4) ? this.showInputNameTsg = true : this.showInputNameTsg = false;
        (this.meetingTypeId == 5) ? this.showInputNameTsn = true : this.showInputNameTsn = false
    }

    changeMeetingParticipants(event: any) {
           this.meetingParticipantsId = event.detail.value
    }

    /**
     * Создаем собрание
     */
    createMeeting() {
        const params = {...this.meetingCreateForm.value}
        params.meetingTypeId = parseInt(this.meetingTypeId)
        params.meetingParticipantsId = parseInt(this.meetingParticipantsId)
        // Получаем параметры из url
        this.mSubRouter = this.activeRouter.params.subscribe((paramsUrl: Params) => {
            this.urlService.urlParams = paramsUrl
        })
        params.houseId = Number(this.urlService.urlParams.houseId)

        // Преобразовали дату из строки  23-09-2023 06:20:55 в объект 20к Sat Sep 23 2023 11:20:39 GMT+0500 (Екатеринбург, стандартное время)
        params.meetingDateBegin = new Date(this.datetimepickerBegin.nativeElement.value.toString())
        params.meetingDateEnd = new Date(this.datetimepickerEnd.nativeElement.value.toString())
        params.meetingDateUpdate = new Date(this.datetimepickerBegin.nativeElement.value.toString())
        params.user_id = this.storageService.getUser();

        this.subscription3$ = this.meetingService.createMeeting(params).subscribe(_ => {
            // Получили обновленные данные и записали в this.dataAdapter.dataSource
            this.meetingService.getMeetings(this.urlService.urlParams);
        });
        // Очистка формы после submit
        this.meetingCreateForm.reset();
        this.router.navigate(['houses/' + params.houseId + '/meetings']);

    }


    /**
     * Очищаем подписку на стрим чтобы не было утечек по памяти
     */
    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());

        if (this.mSubRouter) {
            this.mSubRouter.unsubscribe();
        }
    }

    /**
     * Запускается 3 - им
     */
    init(): void {
        console.log(this.isLoaded)
    }

    /**
     * Св-во нужно для инициализации. Для интерфейса. Нужен для норм работы
     */
    onInit(): void {
        console.log('Запустился onInit()');
    };

    /**
     * Запускается 2 на странице после ngOnInit().
     */
    ngAfterViewInit() {
        console.log('Loading ngAfterViewInit form-create-meeting');
    }


}
